import { useMutation } from "@tanstack/react-query"
import axios from "axios"

export const LoginViaEmail = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/users/login-or-register`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};

export const VerifyOTP = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/users/authorize`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};

export const LoginViaEmailForInvestor = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL_FOR_INVESTOR}/users/login-or-register`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};

export const VerifyOTPForInvestor = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL_FOR_INVESTOR}/users/authorize`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            },
            data: payload
        });
    }, {
        retry: 0,
        onError: (err) => {
            // Handle the error
            console.error(err);
        },
    });
};