import StartupDetail from '../views/startupDetail'
import PitchDeck from '../views/PitchDeck';
import Error404 from '../ui-components/Error404';
import DataRoom from '../views/DataRoom';
import DocumentView from '../views/DataRoom/Document';

// // login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CreatProfileRoutes = {
    path: '',
    children: [
        {
            path: '/',
            element: <Error404 />
        },
        {
            path: '',
            element: <Error404 />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/:slug',
            element: <StartupDetail />
        },

        {
            path: '/:slug/d/pitchdeck',
            element: <PitchDeck />
        },

        {
            path: '/:slug/d',
            element: <DataRoom />
        },

        {
            path: '/:slug/d/:title',
            element: <DocumentView />
        },
    ]
};

export default CreatProfileRoutes;
