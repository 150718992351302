import { Typography } from "@mui/material";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { SaveToList, SaveView } from "../../api";
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import CustomToast from "../../ui-components/CustomToast";
import { delayHoursForView } from "../../utils";

const MainWrappper = ({ children, profileData, startupData, theme }) => {

    const {
        mutateAsync: SaveViewAsync,
        status: saveViewStatus,
        error: saveViewError,
        isLoading: isUpdatingSaveView,
        data: saveViewResponse,
    } = SaveView();

    const {
        mutateAsync: SaveToListAsync,
        status: saveToListStatus,
        error: saveToListError,
        isLoading: isUpdatingSaveToList,
        data: saveToListResponse,
    } = SaveToList();

    const lastView = Cookies.get("last_view")
    const mode = Cookies.get("mode")

    const saveView = () => {
        let request = {
            startup_id: startupData?._id,
            investor_id: profileData?.investor_id
        }
        SaveViewAsync(request).then(response => {
            if (response?.data?.investorInfo?.statusCode == 200) {
                Cookies.set("last_view", new Date())
            }
        }).catch(err => {
            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const addToList = () => {
        let request = {
            startup_id: startupData?._id
        }
        SaveToListAsync(request).then(response => {
            Cookies.remove("mode")
            let currentUrl = window.location?.search
            let newUrl = window.location.href
            newUrl = newUrl.replace(currentUrl, "")
            // window.location.replace(newUrl)
            window.history.replaceState("", "", newUrl)
        });
    }

    useEffect(() => {
        if (profileData != null && startupData != null) {
            if (lastView != null) {
                let hours = moment().diff(moment(lastView), 'hours');
                if (hours >= delayHoursForView) {
                    saveView()
                }
            } else {
                saveView()
            }
            if (mode != null) {
                if (mode == "invited") {
                    if (profileData?.first_name?.trim()?.length != 0 || profileData?.last_name?.trim()?.length != 0) {
                        addToList()
                    }
                }
            }
        }
    }, [profileData, startupData]);

    return (
        <>
            {children}

            <footer style={{
                padding: "1rem", display: "grid", justifyContent: 'center', alignItems: 'center', borderRadius: '0'
            }}>
                <Typography fontWeight={400} fontSize={"0.6rem"} textAlign={'center'} color={`${theme.palette.customColors.g911}`}>Powered by</Typography>
                <BackrrLogoImage alignCenter={true} style={{ height: 50 }} />
            </footer>
        </>
    )
};

export default MainWrappper;
