import React, { useEffect, useRef, useState } from 'react'
import { Language, Logout } from '@mui/icons-material'
import { useTheme } from '@emotion/react';
import { Avatar, Box, Card, Dialog, DialogActions, DialogContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { B300, BLUEBORDER } from '../../ui-components/CustomButton';
import indiaFlag from '../../assets/india_flag.png'
import avatar from '../../assets/avatar.jpg'
import marketplaceIcon from '../../assets/marketplace.png'
import categoryIcon from '../../assets/category.png'
import stageIcon from '../../assets/stage.png'
import sectorIcon from '../../assets/sector.png'
import chequeSizeIcon from '../../assets/cheque_size.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { FetchInvestorDetails, FetchProfileDetailsofStartup } from '../../api';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie';
import { SET_MENU } from '../../store/actions'
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery'
import { toast } from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import ModalComponent from '../../ui-components/ModalComponent'
import { convertCurrencyAmountRange, isEmptyObject } from '../../utils'
import { Helmet } from 'react-helmet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlertDialog from '../../ui-components/popups/AlertPopup'
import AuthWrapperInvestor from '../pagesInvestor/AuthWrapperInvestor'
import MainWrappperInvestor from '../pagesInvestor/MainWrapperInvestor'
import CustomGallery from '../../ui-components/CustomGallery'
import LoginPopupforInvestor from './LoginInvestorPopup'
import Error404 from '../../ui-components/Error404';

const CardHeader = ({ title, action = null, sx = {} }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography variant='h1' fontWeight={700} fontSize={"1rem"} color={'white'} lineHeight={"1rem"} sx={{ ...sx }}>
            {title}
        </Typography>
        {action && <BLUEBORDER
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                display: "flex",
                gap: '0.5rem',
                backgroundColor: '#FFFAF5',
                border: "0"
            }}
            onClick={action}
        >
            <OpenInNewIcon sx={{ fontSize: 20 }} />
        </BLUEBORDER>}
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "", textAlign = "start" }) => {
    const [open, setOpen] = useState(false);

    let content = open ? htmlContent : htmlContent?.substring(0, 300);
    return (
        <>
            {content && content?.length > 0 ?
                <div className='row rich-text'>
                    <div dangerouslySetInnerHTML={{ __html: content }} className='row rich-text' style={{ color: color, textAlign: textAlign }} />
                    {
                        htmlContent?.length > 300 && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: 'white', justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => setOpen(!open)}>{!open ? '...more' : 'See less'}</Typography>
                        // <BLUEBORDER
                        //     component="label"
                        //     role={undefined}
                        //     // tabIndex={-1}
                        //     sx={{
                        //         backgroundColor: 'transparent',
                        //         border: "0",
                        //         padding: "0",
                        //         color: "white",
                        //         textTransform: 'none',
                        //         minWidth: 0
                        //     }}
                        //     onClick={setShowMore}
                        // > <div>{showMore ? '...more' : 'See less'}</div>
                        // </BLUEBORDER>
                    }
                </div>
                : "-"
            }
        </>
    );
};

const PersonDetails = ({ theme, data, index, length }) => {

    const isSmallScreen = (useMediaQuery(theme.breakpoints.down('sm')))[0];
    return <Grid xs={12} sm={12} sx={{
        padding: '0rem', boxShadow: 'none', borderRadius: "8px",
        // border: `1px solid ${theme.palette.customColors.gray300}`,
        overflow: 'hidden', paddingTop: 1, display: 'flex', flexDirection: "row", gap: { xs: "1rem", sm: "1.5rem" },
    }}>
        <Box style={{ flex: 0.3, width: "30%", maxWidth: '5.5rem' }}>
            <img src={data?.image_url} onError={(ev) => { ev.target.src = avatar }} style={{ width: "100%", aspectRatio: 1, padding: '0', objectFit: 'cover', borderRadius: "20px", marginInline: 'auto', display: 'block', border: `1px solid ${theme.palette.customColors.g75}` }} />
        </Box>
        <Box display={"grid"} gap={{ xs: 0.7, sm: 1 }} sx={{ placeContent: 'flex-start', flex: 1 }}>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={1}>
                {data?.name && <Typography textAlign={"start"} sx={{ fontWeight: 600, marginLeft: 0, fontSize: { xs: "0.9rem", sm: "1rem" }, color: 'white' }}>{data?.name}{data?.type && ','} {data?.type}</Typography>}
                {data?.linkedin_url && <LinkedInIcon sx={{ fontSize: 20 }} style={{ display: 'inline-block', marginInlineEnd: 'auto', color: 'white', cursor: 'pointer' }} onClick={() => { window.open(data?.linkedin_url) }} />}
            </Grid>
            {data?.bio && <Typography textAlign={"start"} sx={{ marginRight: 'auto', marginLeft: 0, color: 'white' }}>{data?.bio}</Typography>}
        </Box>
    </Grid>
}

const InvestorDetail = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const portfolioStartups = [
        {
            startup_name: "aastey",
            startup_url: "https://aastey.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2021/08/Astey.png"
        },
        {
            startup_name: "Atirath",
            startup_url: "https://atirath.com",
            startup_logo: "https://kalaari.com/wp-content/uploads/2022/02/Atirath.jpg"
        },
        {
            startup_name: "Baaz Bikes",
            startup_url: "https://www.baaz.bike/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2022/04/1.png"
        },
        {
            startup_name: "Bluestone",
            startup_url: "https://www.bluestone.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2021/08/Bluestone_C.png"
        },
        {
            startup_name: "Bombay Play",
            startup_url: "https://www.bombayplay.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2022/04/bombayplay_logo_solo.png"
        },
        {
            startup_name: "byteXL",
            startup_url: "https://www.bytexl.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2024/07/bytexl-logo.png"
        },
        {
            startup_name: "Cashkaro",
            startup_url: "https://cashkaro.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2021/08/Cashkaro_C.png"
        },
        {
            startup_name: "Chara",
            startup_url: "https://www.chara.co.in/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2021/08/Chara.png"
        },
        {
            startup_name: "Creative Galileo",
            startup_url: "https://www.creativegalileo.com/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2021/10/Creative-Galileo-2.png"
        },
        {
            startup_name: "Clean Electric",
            startup_url: "https://www.cleanelectric.in/",
            startup_logo: "https://kalaari.com/wp-content/uploads/2023/01/Untitled-design.png"
        },
    ]
    const categoryArray = ['Technology', 'Consumer Brand', 'Marketplace', 'Services'
        // {
        //     title: 'Technology',
        //     icon: laptopScreenIcon
        // },
        // {
        //     title: 'Consumer Brand',
        //     icon: mobileShoppingIcon
        // },
        // {
        //     title: 'Marketplace',
        //     icon: marketplaceIcon
        // },
        // {
        //     title: 'Services',
        //     icon: digitalServiceIcon
        // },
    ]
    const sectorArray = ['Information Technology', 'Digital Technology', 'Mobile Accessories', 'Energy Marketplace', 'Digital Marketplace', 'Medical Services'
        // {
        //     title: 'Information Technology',
        //     icon: laptopScreenIcon,
        //     category: "Technology"
        // },
        // {
        //     title: 'Digital Technology',
        //     icon: laptopScreenIcon,
        //     category: "Technology"
        // },
        // {
        //     title: 'Cosmetics Industry',
        //     icon: mobileShoppingIcon,
        //     category: "Consumer Brand"
        // },
        // {
        //     title: 'Mobile Accessories',
        //     icon: mobileShoppingIcon,
        //     category: "Consumer Brand"
        // },
        // {
        //     title: 'Energy Marketplace',
        //     icon: marketplaceIcon,
        //     category: "Marketplace"
        // },
        // {
        //     title: 'Digital Marketplace',
        //     icon: marketplaceIcon,
        //     category: "Marketplace"
        // },
        // {
        //     title: 'Medical Services',
        //     icon: digitalServiceIcon,
        //     category: "Services"
        // }
    ]
    const businessModalArray = [
        {
            title: 'B2B (Business-to-Business)',
            icon: laptopScreenIcon
        },
        {
            title: 'B2C (Business-to-Consumer)',
            icon: laptopScreenIcon
        },
        {
            title: 'B2B2C (Business-to-Business-to-Consumer)',
            icon: mobileShoppingIcon
        },
        {
            title: 'D2C (Direct-to-Consumer)',
            icon: mobileShoppingIcon
        },
        {
            title: 'C2C (Consumer-to-Consumer)',
            icon: marketplaceIcon
        },
        {
            title: 'C2B (Consumer-to-Business)',
            icon: marketplaceIcon
        },
        {
            title: 'B2G (Business-to-Government)',
            icon: digitalServiceIcon
        },
        {
            title: 'P2P (Peer-to-Peer)',
            icon: digitalServiceIcon
        }
    ]
    const stagesArray = ['Pre-Seed', 'Seed', 'Early Stage', 'Growth', 'Expansion'
        // {
        //     title: 'Pre-Seed',
        //     icon: preSeedFullImage,
        //     disable: false
        // },
        // {
        //     title: 'Seed',
        //     icon: seedFullImage,
        //     disable: false
        // },
        // {
        //     title: 'Early Stage',
        //     icon: earlyStageFullImage,
        //     disable: true
        // },
        // {
        //     title: 'Growth',
        //     icon: growthImage,
        //     disable: false
        // },
        // {
        //     title: 'Expansion',
        //     icon: ipoFullImage,
        //     disable: false
        // },
    ]
    const imagesArray = [
        {
            original: "https://kalaari.com/wp-content/uploads/2023/06/Company-Page-Collage.png",
            thumbnail: "https://kalaari.com/wp-content/uploads/2023/06/Company-Page-Collage.png",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
        {
            original: "https://kalaari.com/wp-content/uploads/2022/09/3-2048x1536.png",
            thumbnail: "https://kalaari.com/wp-content/uploads/2022/09/3-2048x1536.png",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
        {
            original: "https://kalaari.com/wp-content/uploads/2022/08/WhatsApp-Image-2022-08-08-at-8.35.30-PM.jpeg",
            thumbnail: "https://kalaari.com/wp-content/uploads/2022/08/WhatsApp-Image-2022-08-08-at-8.35.30-PM.jpeg",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
        {
            original: "https://kalaari.com/wp-content/uploads/2023/09/What-is-it-like-to-work-with-Kalaari_result.webp",
            thumbnail: "https://kalaari.com/wp-content/uploads/2023/09/What-is-it-like-to-work-with-Kalaari_result.webp",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
        {
            original: "https://kalaari.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-22-at-10.50.43-AM.jpeg",
            thumbnail: "https://kalaari.com/wp-content/uploads/2024/10/WhatsApp-Image-2024-10-22-at-10.50.43-AM.jpeg",
            originalHeight: "80%",
            thumbnailHeight: "50rem",
            thumbnailWidth: "40rem",
        },
    ]
    const partners = [
        {
            partner_name: "Kiran Vasireddy",
            linkedin_url: "https://www.linkedin.com/in/kiranvasireddy/",
            partner_image: "https://kalaari.com/wp-content/uploads/2021/08/Kiran_Vasireddy.png",
            bio: 'Partner at Kalaari Capital',
            type: 'Partner'
        },
        {
            partner_name: "Vamshi Krishna Reddy",
            linkedin_url: "https://www.linkedin.com/in/vamshi-reddy-1583312/",
            partner_image: "https://kalaari.com/wp-content/uploads/2021/08/prt-Vamshi.jpg",
            bio: 'Partner at Kalaari Capital',
            type: 'Partner'
        },
        {
            partner_name: "Sampath P",
            linkedin_url: "https://www.linkedin.com/in/prsampath/",
            partner_image: "https://kalaari.com/wp-content/uploads/2021/08/1_result.webp",
            bio: 'Venture Capital | Startups',
            type: 'Venture Capital'
        }
    ]

    const investorData = {
        "name": "Kalaari Capital",
        "profile_image": "https://kalaari.com/wp-content/uploads/2021/08/Kalaari-Logo.png",
        "location": "Bengaluru, Karnataka, India",
        "linkedin_url": "https://www.linkedin.com/company/kalaari-capital/?trk=tyah",
        "overview": "<p>Kalaari Capital is an early-stage, technology-focused venture capital firm based out of Bengaluru, India.</p><p>Since 2006, Kalaari has empowered visionary entrepreneurs building unique solutions that reshape the way Indians live, work, consume and transact. The firm's ethos is to partner early with founders and work with them to navigate the inevitable challenges of fostering ideas into successful businesses.</p><p>At our core, we believe in building long-term relationships based on trust, transparency, authenticity, and respect.</p>",
        "categories": categoryArray,
        "stages": stagesArray,
        "sectors": sectorArray,
        "checque_size": "$50 to $200k USD",
        "portfolio": portfolioStartups,
        "team": partners,
        "gallery": imagesArray
    }

    const [investorDetail, setInvestorDetail] = useState(null)
    const [userDetail, setUserDetail] = useState(null)

    const [showInitialLogo, setShowInitialLogo] = useState(false)

    const [enableShare, setEnableShare] = useState(false)

    const [alertImage, setAlertImage] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [buttonClickType, setButtonClickType] = useState(null)
    const [openLoginPopup, setOpenLoginPopup] = useState(false);

    const [isTokenAvailable, setIsTokenAvailable] = useState(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)

    const [isOverflowCategory, setIsOverflowCategory] = useState(false);
    const [isOverflowSectors, setIsOverflowSectors] = useState(false);
    const [isOverflowStages, setIsOverflowStages] = useState(false);
    const categoryRef = useRef(null);
    const sectorRef = useRef(null);
    const stagesRef = useRef(null);
    const canvasRef = useRef(null);

    const [templateAreas, setTemplateAreas] = useState('"first" "second"')

    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]

    const { data: investorDetailsData, isRefetching: isInvestorDetailsRefetching, isLoading: isInvestorDetailsLoading, refetch: refetchInvestorDetails } = FetchInvestorDetails(slug)
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetailsofStartup(isTokenAvailable)

    useEffect(() => {
        const checkOverflow = () => {
            if (categoryRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = categoryRef.current.scrollHeight > categoryRef.current.clientHeight;
                setIsOverflowCategory(hasOverflow);
            }
            if (sectorRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = sectorRef.current.scrollHeight > sectorRef.current.clientHeight;
                setIsOverflowSectors(hasOverflow);
            }
            if (stagesRef.current) {
                // Check if scrollHeight is greater than clientHeight
                const hasOverflow = stagesRef.current.scrollHeight > stagesRef.current.clientHeight;
                setIsOverflowStages(hasOverflow);
            }
        };

        // Run the overflow check
        checkOverflow();
        // Optional: Re-check on window resize
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    useEffect(() => {
        if (investorDetailsData != null) {
            let investor = investorDetailsData?.data[0]
            let name = ''
            let location = ''
            let profileImage = ''
            let website_url = null
            if (String(investor?.investor_type)?.toLowerCase()?.includes("angel")) {
                name = investor?.personal_information?.first_name + " " + investor?.personal_information?.last_name
                profileImage = investor?.profile_picture?.profile_path
            } else {
                name = investor?.personal_information?.organization_name
                profileImage = investor?.profile_picture?.website_logo_url
                website_url = investor?.personal_information?.organization_website
            }
            // getBackgroundColor(profileImage)
            if (investor?.city || investor?.state || investor?.country) {
                location = investor?.city
                if (investor?.city && investor?.state) {
                    location = location + ", " + investor?.state
                }
                if (((investor?.state && investor?.country) || (investor?.city && investor?.country))) {
                    location = location + ", " + investor?.country
                }
            }
            let investorData = {
                "name": name,
                "profile_image": profileImage,
                "location": location,
                "linkedin_url": investor?.linkedIn_profile?.profile_url,
                // "gallery": imagesArray
            }
            if (website_url != null) {
                investorData = { ...investorData, ...{ website_url: website_url } }
            }
            if (investor?.personal_information?.bio) {
                investorData = { ...investorData, ...{ overview: investor?.personal_information?.bio } }
            }
            if (investor?.category_info?.length > 0) {
                let category_names = investor.category_info.map(item => { return item.name })
                investorData = {
                    ...investorData, ...{
                        categories: category_names
                    }
                }
            }
            if (investor?.stage_info?.length > 0) {
                let stage_names = investor.stage_info.map(item => { return item.name })
                investorData = {
                    ...investorData, ...{
                        stages: stage_names
                    }
                }
            }
            if (investor?.sector_info?.length > 0) {
                let sector_names = investor.sector_info.map(item => { return item.sub_category })
                investorData = {
                    ...investorData, ...{
                        sectors: sector_names
                    }
                }
            }
            if (investor?.investment_size_preference) {
                let invesmentSize = ""
                let currency = investor?.investment_size_preference?.currency === "INR" ? "₹" : investor?.investment_size_preference?.currency === "USD" ? "$" : ""

                if (investor?.investment_size_preference?.min_amount) {
                    invesmentSize = currency + convertCurrencyAmountRange(investor?.investment_size_preference?.min_amount, investor?.investment_size_preference?.currency, 0)
                }
                if (investor?.investment_size_preference?.max_amount) {
                    invesmentSize = invesmentSize + " TO "
                    invesmentSize = invesmentSize + currency + convertCurrencyAmountRange(investor?.investment_size_preference?.max_amount, investor?.investment_size_preference?.currency, 0)
                }
                investorData = {
                    ...investorData, ...{
                        checque_size: invesmentSize
                    }
                }
            }
            if (investor?.partner?.length > 0) {
                investorData = {
                    ...investorData, ...{
                        team: investor?.partner
                    }
                }
                setTemplateAreas(`${templateAreas} "third"`)
            }
            if (investor?.startups?.length > 0) {
                investorData = {
                    ...investorData, ...{
                        portfolio: investor?.startups
                    }
                }
                setTemplateAreas(`${templateAreas} "fourth"`)
            }
            setInvestorDetail(investorData)
        } else {
            setInvestorDetail(null)
        }
    }, [investorDetailsData])

    // const getBackgroundColor = (imageUrl) => {
    //     const canvas = canvasRef.current;
    //     const ctx = canvas.getContext('2d');

    //     const image = new Image();
    //     image.crossOrigin = "anonymous";
    //     image.src = imageUrl;
    //     console.log('Background color:', image);

    //     image.onload = () => {
    //         // Draw the image onto the canvas
    //         ctx.drawImage(image, 0, 0);

    //         // Get the pixel data of the top-left corner (position: 0,0)
    //         const pixelData = ctx.getImageData(0, 0, 1, 1).data;

    //         // Extract RGB values from the pixel data
    //         const [red, green, blue] = pixelData;

    //         // Convert RGB values to hex code
    //         const hexCode = rgbToHex(red, green, blue);

    //         console.log('Background color:', hexCode);
    //     }
    // }
    // const rgbToHex = (r, g, b) =>
    //     `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;

    useEffect(() => {
        setUserDetail(profileDetailsData?.data)
        if (profileDetailsData?.data?.pitch_deck_path == null || profileDetailsData?.data?.pitch_deck_path?.trim()?.length == 0) {
            setEnableShare(false);
        } else {
            setEnableShare(true)
        }
    }, [profileDetailsData])

    useEffect(() => {
        if (userDetail != null && buttonClickType != null) {
            switch (buttonClickType) {
                case 'Share': {
                    handleSharePitchDeck()
                    break;
                }
            }
        }
    }, [userDetail, isTokenAvailable])

    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('email', { path: '/' });
        setIsTokenAvailable(false)
    };

    const handleSharePitchDeck = () => {
        if (isTokenAvailable) {
            if (enableShare) {
                sharePitchDeck()
            } else {
                completeOnboarding()
            }
        } else {
            setButtonClickType("Share")
            setOpenLoginPopup(true)
        }
    }

    const sharePitchDeck = () => {
        alert("share Pitch Deck " + userDetail?.pitch_deck_path)
    }

    const completeOnboarding = () => {
        alert("complete onboarding")
        // window.open(`http://localhost:3000/onboarding?token=${Cookies.get('jwt')}&is_profile_completed=false`, "_blank")
    }

    const commonCardStyles = {
        padding: '1rem',
        // paddingBottom: '1rem',
        boxShadow: 'none',
        background: 'transparent',
        // backgroundColor: "#FFFAF5",
        overflow: 'hidden',
        // border: `1px solid ${theme.palette.customColors.indigo600}`,
        border: `1px solid #FFF`,
        borderRadius: '15px',
    };

    const commonInvestmentFocusStyles = {
        borderRadius: '8px',
        backgroundColor: 'white',
        padding: '1rem',
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex'
    };

    const commonInvestmentFocusIconStyles = {
        height: '2.5rem',
        width: '2.5rem',
        objectFit: 'contain',
    };

    const getAvatarProps = (name) => {
        const initials = name
            ? name
                .split(' ')
                .map((n) => n[0])
                .join('')
                .toUpperCase()
            : '';
        return {
            sx: {
                bgcolor: '#FFFFFF',
                color: '#3538CD',
                width: "8rem",
                height: "8rem",
                fontSize: "4rem",
                fontWeight: 500,
                borderRadius: "5rem",
                marginInline: 'auto',
            },
            children: initials,
        };
    };

    return (

        <AuthWrapperInvestor sx={{ height: '100%' }} theme={theme}>
            <MainWrappperInvestor profileData={userDetail} investorData={investorDetail} theme={theme} className={'investor'} footerColor={'#FFF'}>
                <Helmet>
                    <title>{investorDetail != null ? investorDetail?.name + " | Backrr" : "Backrr"}</title>
                    <meta name="description" content={investorDetail?.overflow} />
                    <meta property="og:title" content={investorDetail != null ? investorDetail?.name + " | Backrr" : "Backrr"} />
                    <meta property="og:description" content={investorDetail?.overview} />
                </Helmet>

                <main className='investor'>
                    {(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) && <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={handleLogOut}><Logout sx={{ color: "#FFF" }} /></IconButton>
                    </Grid>}
                    {
                        !isInvestorDetailsLoading && (investorDetailsData?.data === undefined || investorDetailsData?.data == 'Slug does nt exist.') ? <Error404 /> :
                            <>
                                {
                                    isInvestorDetailsLoading ?
                                        <Grid xs={12} container display={"block"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                                            maxWidth: '900px !important',
                                            marginInline: 'auto',
                                            height: '100vh',
                                            paddingTop: '0.5rem !important'
                                        }}>
                                            <Grid sx={{ width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                                                <Card sx={{ padding: '1rem', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent' }}>
                                                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                                                        <div className='skeleton-investor' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '3rem', width: '6rem', height: '8rem', borderRadius: "5rem" }} />
                                                        <div className='skeleton-investor' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '4rem', width: '50rem', height: '5rem' }} />
                                                        <div className='skeleton-investor' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '2rem', width: '50rem', height: '10rem' }} />
                                                        <div className='skeleton-investor' style={{ marginInline: 'auto', padding: "0 1rem 0 1rem", marginTop: '2rem', width: '50rem', height: '10rem' }} />
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid> :
                                        <Grid container xs={12} gap={"1.25rem"} display={"grid"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }}
                                            sx={{
                                                maxWidth: '900px !important',
                                                marginInline: 'auto',
                                                height: '100%',
                                                paddingTop: '1.5rem !important'
                                            }}
                                            gridTemplateAreas={templateAreas}
                                            gridTemplateColumns={'1fr'} justifyContent={"space-between"}>
                                            <Grid sx={{ gridArea: 'first', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item >
                                                <Card sx={{ padding: '1rem', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent' }}>
                                                    <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"}>
                                                        <Grid sx={{ borderRadius: '5rem', width: '8rem', height: '8rem', marginInline: 'auto', backgroundColor: "#FFF", alignItems: 'center', justifyContent: 'center',  }}>
                                                            {!showInitialLogo ? <img src={investorDetail?.profile_image} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: '8rem', height: '8rem', borderRadius: '5rem', marginInline: 'auto', display: "block", objectFit: 'contain' }} /> : <Avatar sx={{}}  {...getAvatarProps(investorDetail?.name)} />}
                                                        </Grid>
                                                        <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                                            <Typography variant='titleInvestor' sx={{ fontSize: "1.3rem", textAlign: 'center', marginInline: 'auto' }}>{investorDetail?.name}</Typography>
                                                        </Grid>
                                                        <Typography variant='bodyTextRegularInvestor' sx={{ fontSize: { xs: "0.8rem", md: "0.8rem" }, textAlign: 'center', marginInline: 'auto' }}>{investorDetail?.location}
                                                            {investorDetailsData?.data[0]?.country == "India" && <span>
                                                                <img src={indiaFlag} rel='preload' style={{ width: "1.2rem", marginLeft: '5px' }} />
                                                            </span>}
                                                        </Typography>

                                                        <Grid spacing={2} marginTop={1} xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                                            <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><LinkedInIcon sx={{ fontSize: 30, borderRadius: 10, color: "#FFF" }} style={{ display: 'inline-block' }} onClick={() => { window.open(investorDetail?.linkedin_url) }} /></button>
                                                            {investorDetail?.website_url && <button type='button' style={{ backgroundColor: 'transparent', padding: 0 }}><Language sx={{ fontSize: 30, borderRadius: 10, color: "#FFF" }} style={{ display: 'inline-block' }} onClick={() => { window.open(investorDetail?.website_url) }} /></button>}
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                                {investorDetail?.overview && <Card sx={{ ...commonCardStyles }}>
                                                    <CardHeader title={"Overview"} />
                                                    <RichTextDisplay htmlContent={investorDetail?.overview} color={"white"} textAlign='justify' />
                                                </Card>}
                                            </Grid>
                                            <Grid sx={{ gridArea: 'second', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                                <Card sx={{ ...commonCardStyles }}>
                                                    <CardHeader title={"Investment Focus"} />
                                                    <Grid
                                                        container
                                                        display={'flex'}
                                                        xs={12}
                                                        sx={{
                                                            overflow: "hidden",
                                                            justifyContent: 'start',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {investorDetail?.categories && <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                                <Grid flex={0.7}>
                                                                    <img src={categoryIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                                    <Grid mt={'5px'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Categories</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid flex={1} alignItems={'start'}>
                                                                    <Grid ref={categoryRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorDetail?.categories?.join(", ")}</Typography>
                                                                    </Grid>
                                                                    <span>
                                                                        {
                                                                            isOverflowCategory && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                                setAlertImage(categoryIcon)
                                                                                setAlertHeading('Categories')
                                                                                setAlertMessage(investorDetail?.categories?.join(", "))
                                                                                setShowAlert(true)
                                                                            }}>...more</Typography>
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}
                                                        {investorDetail?.stages && <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus'>
                                                                <Grid flex={0.7}>
                                                                    <img src={stageIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                                    <Grid mt={'5px'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Stages</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid flex={1} alignItems={'start'}>
                                                                    <Grid ref={stagesRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorDetail?.stages?.join(", ")}</Typography>
                                                                    </Grid>
                                                                    <span>
                                                                        {
                                                                            isOverflowStages && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                                setAlertImage(stageIcon)
                                                                                setAlertHeading('Stages')
                                                                                setAlertMessage(investorDetail?.stages?.join(", "))
                                                                                setShowAlert(true)
                                                                            }}>...more</Typography>
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}
                                                        {investorDetail?.sectors && <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                                <Grid flex={0.7}>
                                                                    <img src={sectorIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                                    <Grid mt={'5px'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Sectors</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid flex={1} alignItems={'start'}>
                                                                    <Grid ref={sectorRef} display={'flex'} flex={1} maxHeight={'3.5rem'} overflow={'hidden'} alignItems={'start'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorDetail?.sectors?.join(", ")}</Typography>
                                                                    </Grid>

                                                                    <span>
                                                                        {
                                                                            isOverflowSectors && <Typography display={"flex"} variant='title' sx={{ fontSize: "0.8rem", textAlign: 'center', lineHeight: 1, color: theme.palette.customColors.blue, justifyContent: 'flex-end', marginRight: "1rem", cursor: 'pointer' }} onClick={() => {
                                                                                setAlertImage(sectorIcon)
                                                                                setAlertHeading('Sectors')
                                                                                setAlertMessage(investorDetail?.sectors?.join(", "))
                                                                                setShowAlert(true)
                                                                            }}>...more</Typography>
                                                                        }
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}
                                                        {investorDetail?.checque_size && <Grid item width={{ xs: "100%", sm: "50%" }} padding={"0.5rem"}>
                                                            <Grid sx={{ ...commonInvestmentFocusStyles }} className='investment-focus' >
                                                                <Grid flex={0.7}>
                                                                    <img src={chequeSizeIcon} style={{ ...commonInvestmentFocusIconStyles }} />
                                                                    <Grid mt={'5px'}>
                                                                        <Typography variant='title' sx={{ fontSize: "0.8rem", marginInline: 'auto', lineHeight: '1rem', color: theme.palette.customColors.blue }}>Checque Size</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid display={'flex'} flex={1} maxHeight={'4rem'} overflow={'hidden'} alignItems={'start'}>
                                                                    <Typography variant='title' sx={{ fontSize: "0.8rem", textAlign: 'left', lineHeight: 1.4, color: theme.palette.customColors.blue }}>{investorDetail?.checque_size}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>}
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                            {investorDetailsData?.data[0]?.partner?.length > 0 && <Grid sx={{ gridArea: 'third', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                                <Card sx={{ ...commonCardStyles }}>
                                                    <CardHeader title={"Team"} />
                                                    <Grid
                                                        container
                                                        display={'flex'}
                                                        gap={2}
                                                        xs={12}
                                                        sx={{
                                                            overflow: "hidden",
                                                            justifyContent: 'start',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {
                                                            investorDetail?.team?.map((person, index) => {
                                                                return <PersonDetails theme={theme} data={person} key={index} index={index} length={investorDetail?.team?.length} />
                                                            })
                                                        }
                                                    </Grid>
                                                </Card>
                                            </Grid>}
                                            {investorDetailsData?.data[0]?.startups?.length > 0 && <Grid sx={{ gridArea: 'fourth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                                <Card sx={{ ...commonCardStyles }}>
                                                    <CardHeader title={"Portfolio"} />
                                                    <Grid
                                                        container
                                                        display={'flex'}
                                                        xs={12}
                                                        sx={{
                                                            overflow: "hidden",
                                                            justifyContent: 'start',
                                                            alignItems: 'start'
                                                        }}
                                                    >
                                                        {
                                                            investorDetail?.portfolio?.map(item => {
                                                                return (
                                                                    <Grid item width={{ xs: "50%", sm: "33.33%", md: "25%", lg: "20%", xl: "16.66%" }} padding={"0.5rem"}>
                                                                        <Grid sx={{ border: `1px solid ${theme.palette.customColors.g75}`, padding: "0.4rem", cursor: 'pointer', borderRadius: "8px", backgroundColor: 'white' }} alignItems={'center'} justifyContent={'center'} onClick={() => { window.open(item.startup_url) }}>
                                                                            <img src={item.startup_logo} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "4rem", height: "4rem", borderRadius: '8px', marginInline: 'auto', display: "block", objectFit: 'contain' }} />
                                                                        </Grid>
                                                                        <Grid display={'flex'} alignItems={"center"} justifyContent={"center"}>
                                                                            <Typography variant='titleNormal' sx={{ fontSize: "1rem", textAlign: 'center', marginInline: 'auto', cursor: 'pointer', color: 'white' }} onClick={() => { window.open(item.startup_url) }}>{item.startup_name}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Card>
                                            </Grid>}
                                            {/* <Grid sx={{ gridArea: 'fifth', width: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                                                <Card sx={{ ...commonCardStyles }}>
                                                    <CardHeader title={"Gallery"} />
                                                    <Grid
                                                        container
                                                        display={'flex'}
                                                        xs={12}
                                                        sx={{
                                                            overflow: "hidden",
                                                            justifyContent: 'start',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <CustomGallery imagesArray={investorDetail?.gallery} theme={theme} />
                                                    </Grid>
                                                </Card>
                                            </Grid> */}
                                        </Grid >

                                }
                                <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
                                    <B300 onClick={handleSharePitchDeck} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid #FFF`, padding: '16px', fontWeight: 500, borderRadius: "8px", height: '40px', fontSize: '0.8rem' }} className="share-button" style={{ lineHeight: '15px' }}>
                                        {`Share Pitch Deck with ${investorDetail?.name}`}
                                    </B300>
                                </Grid>
                            </>
                    }
                    <Dialog fullScreen={true} open={openLoginPopup} onClose={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) }} sx={{}}>
                        <DialogActions>
                            <Tooltip title="Close">
                                <IconButton disableRipple={true} sx={{ padding: 0 }}>
                                    <CloseIcon width={40} height={40} style={{ marginRight: 0 }} onClick={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined); setButtonClickType(null) }} />
                                </IconButton>
                            </Tooltip>
                        </DialogActions>
                        <DialogContent sx={{ padding: 0 }}>
                            <LoginPopupforInvestor handleClose={() => {
                                setOpenLoginPopup(false);
                                setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined);
                                refetchProfileDetails()
                            }} />
                        </DialogContent>
                    </Dialog>
                    <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null); setAlertImage(null) }} heading={alertHeading} subheading={alertMessage} image={alertImage} />
                    {/* <canvas ref={canvasRef} /> */}
                </main>
            </MainWrappperInvestor>
        </AuthWrapperInvestor>
    )
}

export default InvestorDetail