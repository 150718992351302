import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/CustomInputField";
import { B300 } from "../../ui-components/CustomButton";
import * as Yup from "yup";
import { intrestOptions, userTypeOptions } from "../../utils";
import CustomValidationText from "../../ui-components/CustomValidationText";

const UserTypePopup = ({ data, handleClose, handleSubmit }) => {
  const theme = useTheme();
  const [selectedInterest, setSelectedInterest] = useState(null)

  const validations = Yup.object(
    {
      type: Yup.string("Please select your type").required("Please select your type")
    }
  )

  const formik = useFormik({
    initialValues: {
      type: ""
    },
    // validateOnMount: true,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "20px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Select User Type
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Save your user type to customize your experience.
              </Typography>
            </Grid>
            <Grid item sx={12} >
              <div className="grid">
                {
                  userTypeOptions.map(item => {
                    return (
                      <button type="button" className={`item ${item.label === selectedInterest && "selected-item"}`} onClick={() => { setSelectedInterest(item.label); formik.setFieldValue("type", item.value); }}>{item.label}</button>
                    )
                  })
                }
              </div>
              {formik.errors.type && <CustomValidationText value={formik.errors.type} />}
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Save
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default UserTypePopup;
