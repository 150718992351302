import React from 'react'
import { useLocation } from 'react-router-dom';
import Cookies from "js-cookie"
import LoginPopup from '../startupDetail/LoginPopup';

const LinkedInRedirect = () => {
    // let code = new URLSearchParams(window.location.search).get("code");
    // let redirect_uri = new URLSearchParams(window.location.search).get("state");

    const codeCookie = Cookies.get("code");

    // In the callback page or the new tab that handles LinkedIn OAuth
    const callbackPage = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code'); // Get the authorization code
        Cookies.set("code", code)

        if (code) {
            // Send the code back to the original tab
            window.opener.postMessage({
                type: 'linkedin-login-success',
                data: code
            }, window.location.origin);

            // Close the new tab once the process is complete
            // window.close();
        }
    };

    callbackPage();

    // console.log(codeCookie,"code")

    return (
        <div>
            {/* {codeCookie}
            <br /> */}
            <LoginPopup />

        </div>
    )
}

export default LinkedInRedirect