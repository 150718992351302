import React from 'react'
import video from "../assets/sample-5s.mp4"
import Image from '../assets/pitchdeck.png'
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom'


const CustomPDFThumbnail = ({ type, height = 300, url, thumbnail = Image, action = null }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <div style={{ width: '100%', minHeight: 50, display: "flex", alignItems: "center", textAlign: 'center', justifyContent: 'center', overflow: 'hidden', background: 'transparent', maxHeight: type !== "video" ? 350 : 350}}>
            {type === "video" ?
                <video controls width={"100%"} style={{ objectFit: 'contain', aspectRatio: 2.2}}>
                {/* <video controls width={"100%"} style={{ objectFit: 'fill', transform: 'translate(0px,-40px)' }}> */}
                    <source src={url+"#t=0.001"}></source>
                </video>
                : <button type='button' style={{backgroundColor: 'transparent', padding: 0}}><img onClick={action} src={thumbnail} onError={(e) => { e.target.src = Image}}  width={"100%"} /></button>}
        </div>
    )
}

export default CustomPDFThumbnail