import AuthWrapper1 from '../pages/AuthWrapper1'
import { Box, Card, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import PieChart from '../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { handleDateValues, notEmpty } from '../../utils';
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { CardContentList } from '../../ui-components/CardContentList';

const CardHeader = ({ title }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    return (
        <>
            {htmlContent && htmlContent?.length > 0 ?
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} className='row rich-text' style={{ color: color, padding: '1rem' }} />
                : "-"
            }
        </>
    );
};

const RoundDetail = ({ data: roundDetails, handleClose, startupDetail }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    let gridTemplateAreas = []
    let nameAndTypeDetails = []
    let roundSizeAndValuationDetails = []
    let exitStrategyDetails = []
    let otherDetails = []

    if (roundDetails?.round_name != null || startupDetail?.round_type_info != null || startupDetail?.security_type_info != null) {
        gridTemplateAreas.push("\"" + "first" + "\"")
        if (notEmpty(roundDetails?.round_name)) {
            nameAndTypeDetails.push({
                label: "Round Name", value: roundDetails?.round_name
            })
        }
        if (notEmpty(startupDetail?.round_type_info?.name)) {
            nameAndTypeDetails.push({
                label: "Round Type", value: startupDetail?.round_type_info?.name
            })
        }
        if (notEmpty(startupDetail?.security_type_info?.name)) {
            nameAndTypeDetails.push({
                label: "Type of Security", value: startupDetail?.security_type_info?.name
            })
        }
    }
    if (notEmpty(roundDetails?.fund_utilization_highlights) || notEmpty(roundDetails?.fund_allocation_percentages)) {
        gridTemplateAreas.push("\"" + "aside" + "\"")
    }
    if (notEmpty(roundDetails?.round_highlights) || notEmpty(startupDetail?.total_round_size_final) || notEmpty(startupDetail?.round_valuation_final) || notEmpty(startupDetail?.minimum_cheque_size_final) || notEmpty(roundDetails?.received_termsheet) || notEmpty(startupDetail?.committed_funding_amount_final)) {
        gridTemplateAreas.push("\"" + "second" + "\"")
        if (notEmpty(startupDetail?.total_round_size_final)) {
            roundSizeAndValuationDetails.push({
                label: "Total Round Size", value: startupDetail?.total_round_size_final
            })
        }
        if (notEmpty(startupDetail?.round_valuation_final)) {
            roundSizeAndValuationDetails.push({
                label: "Round Valuation", value: startupDetail?.round_valuation_final
            })
        }
        if (notEmpty(startupDetail?.minimum_cheque_size_final)) {
            roundSizeAndValuationDetails.push({
                label: "Minimum Cheque Size", value: startupDetail?.minimum_cheque_size_final
            })
        }
        if (notEmpty(roundDetails?.received_termsheet)) {
            roundSizeAndValuationDetails.push({
                label: "Have you already received any Term Sheet?", value: roundDetails?.received_termsheet ? "Yes" : 'No'
            })
        }
        if (notEmpty(startupDetail?.committed_funding_amount_final)) {
            roundSizeAndValuationDetails.push({
                label: "What funding amount has been committed so far?", value: startupDetail?.committed_funding_amount_final
            })
        }
    }
    if (notEmpty(roundDetails?.investors)) {
        gridTemplateAreas.push("\"" + "third" + "\"")
    }
    if (notEmpty(roundDetails?.traction_highlights) || notEmpty(roundDetails?.key_metrics)) {
        gridTemplateAreas.push("\"" + "forth" + "\"")
    }
    if (notEmpty(roundDetails?.faqs)) {
        gridTemplateAreas.push("\"" + "fifth" + "\"")
    }
    if (notEmpty(roundDetails?.exit_highlights) || notEmpty(roundDetails?.runway_left) || notEmpty(startupDetail?.monthly_net_burn_final) || notEmpty(startupDetail?.final_round_start_date) || notEmpty(startupDetail?.final_round_closing_date) || notEmpty(roundDetails?.round_status_info)) {
        gridTemplateAreas.push("\"" + "sixth" + "\"")
        if (notEmpty(roundDetails?.runway_left)) {
            exitStrategyDetails.push({
                label: "Runway", value: roundDetails?.runway_left == 1 ? roundDetails?.runway_left + " Month" : roundDetails?.runway_left + " Months"
            })
        }
        if (notEmpty(startupDetail?.monthly_net_burn_final)) {
            exitStrategyDetails.push({
                label: "Monthly Net Burn", value: startupDetail?.monthly_net_burn_final
            })
        }

        if (notEmpty(startupDetail?.final_round_start_date) || notEmpty(startupDetail?.final_round_closing_date) || notEmpty(startupDetail?.round_status_info?.name)) {
            if (notEmpty(startupDetail?.final_round_start_date)) {
                otherDetails.push({
                    label: "Round Start Date", value: handleDateValues(startupDetail?.final_round_start_date, "-")
                })
            }
            if (notEmpty(startupDetail?.final_round_closing_date)) {
                otherDetails.push({
                    label: "Round Closing Date", value: handleDateValues(startupDetail?.final_round_closing_date, "-")
                })
            }
            if (notEmpty(startupDetail?.round_status_info?.name)) {
                otherDetails.push({
                    label: "Round Status", value: startupDetail?.round_status_info?.name
                })
            }
        }
    }

    return (
        <AuthWrapper1 sx={{ height: '100%' }} theme={theme}>
            <Grid item container xs={12} gap={"1.25rem"} display={"grid"} sx={{ padding: '1rem', backgroundColor: '#FFFAF5' }} gridTemplateAreas={`${gridTemplateAreas.join(" ")}`}
                gridTemplateColumns={'1fr'} justifyContent={"space-between"}>
                {
                    (notEmpty(roundDetails?.round_name) || notEmpty(startupDetail?.round_type_info?.name) || notEmpty(startupDetail?.security_type_info?.name)) && <Grid sx={{ gridArea: 'first', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', background: 'none' }}>
                            <CardHeader title={"Name & Type"} />
                            <CardContentList data={nameAndTypeDetails} />
                        </Card>
                    </Grid>
                }
                {
                    (notEmpty(roundDetails?.fund_utilization_highlights) || notEmpty(roundDetails?.fund_allocation_percentages)) && <Grid sx={{ gridArea: 'aside', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', background: 'none' }}>
                            <CardHeader title={"Fund Utilization"} />
                            {notEmpty(roundDetails?.fund_utilization_highlights) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Fund Utilization Highlights
                                </Typography>
                                <RichTextDisplay htmlContent={roundDetails?.fund_utilization_highlights} color={theme.palette.customColors.g200} />
                            </Card>}
                            {notEmpty(roundDetails?.fund_allocation_percentages) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden'
                            , marginBottom: 0
                            //  marginBottom: '1.25rem'
                              }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Fund Allocation
                                </Typography>
                                <Card sx={{ padding: '1rem', overflow: 'hidden' }}>
                                    <PieChart graphData={roundDetails?.fund_allocation_percentages} height='100%' width="100%" />
                                </Card>
                            </Card>}
                        </Card>
                    </Grid>
                }
                {
                    (notEmpty(roundDetails?.round_highlights) || notEmpty(startupDetail?.total_round_size_final) || notEmpty(startupDetail?.round_valuation_final) || notEmpty(startupDetail?.minimum_cheque_size_final) || notEmpty(roundDetails?.received_termsheet) || notEmpty(startupDetail?.committed_funding_amount_final)) && <Grid sx={{ gridArea: 'second', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', background: 'none' }}>

                            <CardHeader title={"Round Size & Valuation"} />
                            {notEmpty(roundDetails?.round_highlights) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Round Highlights
                                </Typography>
                                <RichTextDisplay htmlContent={roundDetails?.round_highlights} color={theme.palette.customColors.g200} />
                            </Card>}
                            <CardContentList data={roundSizeAndValuationDetails} />
                        </Card>
                    </Grid>
                }
                {
                    notEmpty(roundDetails?.investors) && <Grid sx={{ gridArea: 'third', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' , background: 'none'}}>
                            <CardHeader title={"Current Investors"} />
                            <Grid
                                container
                                xs={12}
                                sx={{
                                    border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{
                                            padding: "12px 16px",
                                            backgroundColor:
                                                theme?.palette?.customColors?.g200
                                        }}>
                                            <TableCell>
                                                <Typography fontWeight={400} color={theme?.palette?.customColors?.g50}>Investor Name</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography fontWeight={400} color={theme?.palette?.customColors?.g50}>
                                                    Investor Email
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {roundDetails?.investors?.length > 0 && roundDetails?.investors?.map((investor, index) => {
                                            return <TableRow
                                                item
                                                key={index}
                                                xs={12}
                                                display={"flex"}
                                                justifyContent={"space-between"}
                                                sx={{
                                                    padding: "12px 16px",
                                                    backgroundColor:
                                                        index % 2 === 0 ? theme?.palette?.customColors?.inputField : '',
                                                }}
                                            >
                                                <TableCell>
                                                    <Typography fontWeight={500} color={theme?.palette?.customColors?.g300}>
                                                        {investor?.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography fontWeight={400} color={theme?.palette?.customColors?.g200}>
                                                        {investor?.email}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        })}

                                        {roundDetails?.investors?.length == 0 && <TableRow
                                            item
                                            xs={12}
                                            display={"flex"}
                                            justifyContent={"space-between"}
                                            sx={{
                                                padding: "12px 16px",
                                                backgroundColor:
                                                    theme?.palette?.customColors?.inputField,
                                            }}
                                        >
                                            <TableCell colSpan={2} marginInline={"auto"} sx={{ textAlign: 'center' }}>No Current Investors</TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Card>
                    </Grid>
                }
                {
                    (notEmpty(roundDetails?.traction_highlights) || notEmpty(roundDetails?.key_metrics)) && <Grid sx={{ gridArea: 'forth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', background: 'none' }}>
                            <CardHeader title={"Current Traction & Runway"} />
                            {notEmpty(roundDetails?.traction_highlights) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Traction Highlight
                                </Typography>
                                <RichTextDisplay htmlContent={roundDetails?.traction_highlights} color={theme.palette.customColors.g200} />
                            </Card>}

                            {notEmpty(roundDetails?.key_metrics) && roundDetails?.key_metrics?.map((metric, idx) => {
                                return <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden',
                                 marginBottom: '0rem' 
                                //  marginBottom: '1.25rem' 
                                 }} mt={'0.75rem'} >
                                    <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                        {metric?.heading}
                                    </Typography>
                                    <Card sx={{ padding: '1rem', overflow: 'hidden' }}>
                                        <Grid
                                            container
                                            xs={12}
                                            sx={{
                                                border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {metric?.properties?.map((item, index) => {
                                                return <Grid
                                                    item
                                                    key={index}
                                                    xs={12}
                                                    display={"flex"}
                                                    justifyContent={"space-between"}
                                                    sx={{
                                                        padding: "12px 16px",
                                                        backgroundColor:
                                                            index % 2 === 0 ? theme?.palette?.customColors?.inputField : '',
                                                    }}
                                                >
                                                    <Typography>{item?.key}</Typography>
                                                    <Typography>
                                                        {item?.value}
                                                    </Typography>
                                                </Grid>
                                            })}
                                        </Grid>
                                    </Card>
                                </Card>
                            })}
                        </Card>
                    </Grid>
                }
                {
                    notEmpty(roundDetails?.faqs) && <Grid sx={{ gridArea: 'fifth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" } }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', marginInline: { xs: "0.5rem -0.5rem", sm: 0 } , background: 'none'}}>
                            <CardHeader title={"FAQs"} />
                            {
                                roundDetails?.faqs && roundDetails?.faqs?.length > 0 && roundDetails?.faqs?.map(({ question, answer }, index) => {
                                    // return <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                    //     <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} mb={1}>
                                    //         {question}
                                    //     </Typography>
                                    //     <RichTextDisplay htmlContent={answer} />
                                    // </Card>
                                    return (
                                        <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: index === roundDetails?.faqs?.length - 1  ? 0 : '1.25rem' }}>
                                            <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                                {question}
                                            </Typography>
                                            <RichTextDisplay htmlContent={answer} color={theme.palette.customColors.g200} />
                                        </Card>
                                    )
                                })
                            }
                            {
                                roundDetails?.faqs && roundDetails?.faqs?.length === 0 && <>
                                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                        <Typography fontWeight={500} fontSize={"1rem"} color={theme.palette.customColors.g200} textAlign={"center"}>
                                            No FAQs available
                                        </Typography>
                                    </Card>
                                </>
                            }
                        </Card>
                    </Grid>
                }
                {
                    (notEmpty(roundDetails?.exit_highlights) || notEmpty(roundDetails?.runway_left) || notEmpty(startupDetail?.monthly_net_burn_final) || notEmpty(startupDetail?.final_round_start_date) || notEmpty(startupDetail?.final_round_closing_date) || notEmpty(roundDetails?.round_status_info)) && <Grid sx={{ gridArea: 'sixth', width: { xs: "95vw", sm: "100%" }, maxWidth: { xs: '95%', sm: "100%" }, marginInline: 0 }} item>
                        <Card sx={{ padding: '0 1rem', boxShadow: 'none', borderRadius: "8px", border: `0px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', background: 'none' }}>

                            <CardHeader title={"Exit Strategy & Timeline"} />
                            {notEmpty(roundDetails?.exit_highlights) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem' }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Exit Highlight
                                </Typography>
                                <RichTextDisplay htmlContent={roundDetails?.exit_highlights} color={theme.palette.customColors.g200} />
                            </Card>}
                            <CardContentList data={exitStrategyDetails} />
                            {(notEmpty(startupDetail?.final_round_start_date) || notEmpty(startupDetail?.final_round_closing_date) || notEmpty(startupDetail?.round_status_info?.name)) && <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, overflow: 'hidden', marginBottom: '1.25rem', marginTop: '1rem' }}>
                                <Typography fontWeight={700} fontSize={"1rem"} color={theme.palette.customColors.g200} sx={{ backgroundColor: theme.palette.customColors.inputField, padding: '1rem', border: `1px solid ${theme.palette.customColors.divider}`, borderRadius: "8px 8px 0 0", }}>
                                    Other Details
                                </Typography>
                                <Card sx={{ padding: '1rem', overflow: 'hidden' }}>

                                    <CardContentList data={otherDetails} />
                                </Card>
                            </Card>}
                        </Card>
                    </Grid>
                }
            </Grid>
        </AuthWrapper1>
    )
}

export default RoundDetail
