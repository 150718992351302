import Cookies from 'js-cookie';

export const verifyToken = (err) => {
    if (err?.response?.status == 401) {
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('name', { path: '/' });
        Cookies.remove('user_id', { path: '/' });
        Cookies.remove('email', { path: '/' });
        window.location.href = '/login';
    }
};

export const RetryOnError = (failureCount, error) => (error?.response?.status != 401 && failureCount < 2 ? true : false);
