import React, { useEffect, useState } from 'react'
import { Add, Logout, ThumbUp, RateReview, ArrowBack } from '@mui/icons-material'
import { useTheme } from '@emotion/react';
import AuthWrapper1 from '../pages/AuthWrapper1';
import { Box, Card, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import { B300, WHITEBG } from '../../ui-components/CustomButton';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage';
import PerfectScrollbar from "react-perfect-scrollbar";
import { FetchProfileDetails, FetchStartupDetails, FetchStartupDocuments, FetchStartupPitchDeck, FetchStatus, SaveFeedback, SaveInterest, SavePersonalInfo, SaveToList, SaveUserType } from '../../api';
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Cookies from 'js-cookie';
import { SET_MENU } from '../../store/actions'
import { toast } from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import ModalComponent from '../../ui-components/ModalComponent'
import NameInvestorPopup from '../startupDetail/NameInvestorPopup';
import SaveInterestPopup from '../startupDetail/SaveInterestPopup';
import UserTypePopup from '../startupDetail/UserTypePopup';
import { Helmet } from 'react-helmet';
import StartupLogo from '../../assets/business.png';
import FeedbackPopup from '../startupDetail/FeedbackPopup'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import moment from 'moment';
import Error404 from '../../ui-components/Error404';
import { getFileCategory, getFileIcon, notEmpty } from '../../utils';
import AlertDialog from '../../ui-components/popups/AlertPopup';
import MainWrappper from '../pages/MainWrapper';
import NoData from '../../ui-components/NoData';
import LoginPopup from '../startupDetail/LoginPopup';
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";

const documentsArray = [
    {
        _id: 0,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck",
        updatedAt: "2024-10-04T18:30:00.000Z",
        size: "2KB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },
    {
        _id: 1,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck 2",
        updatedAt: "2024-10-05T18:30:00.000Z",
        size: "40KB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },
    {
        _id: 3,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck 3",
        updatedAt: "2024-10-03T18:30:00.000Z",
        size: "1MB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },
    {
        _id: 4,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck 4",
        updatedAt: "2024-10-03T18:30:00.000Z",
        size: "2MB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },
    {
        _id: 5,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck 5",
        updatedAt: "2024-10-03T18:30:00.000Z",
        size: "2MB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    },
    {
        _id: 6,
        thumbnail_path: "https://startups-dev-pub.s3.ap-south-1.amazonaws.com/old-we360/logo.png",
        filename: "Pitch Deck 6",
        updatedAt: "2024-10-03T18:30:00.000Z",
        size: "2MB",
        document_path: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
    }
]

const DataRoom = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [userDetail, setUserDetail] = useState(null)

    const [openNamePopup, setOpenNamePopup] = useState(false);
    const [openInterestPopup, setOpenInterestPopup] = useState(false);
    const [openFeedbackPopup, setOpenFeedbackPopup] = useState(false);
    const [openTypePopup, setOpenTypePopup] = useState(false);
    const [openLoginPopup, setOpenLoginPopup] = useState(false);
    const [type, setType] = useState(null)

    const [documents, setDocuments] = useState([])

    const [listMode, setListMode] = useState(true)

    const [showInitialLogo, setShowInitialLogo] = useState(false)
    const [startupDetail, setStartupDetail] = useState(null)

    const [enableInterest, setEnableInterest] = useState(true)
    const [enableFeedback, setEnableFeedback] = useState(true)
    const [enableAddToList, setEnableAddToList] = useState(true)

    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeading, setAlertHeading] = useState(null)

    const [buttonClickType, setButtonClickType] = useState(null)

    const [showNotFound, setShowNotFound] = useState(false)

    const [isTokenAvailable, setIsTokenAvailable] = useState(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined)

    // const slug = Cookies.get("slug")?.trim()

    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]

    const { data: fullStartupDetailsData, isRefetching: isFullStartupDetailsRefetching, isLoading: isFullStartupDetailsLoading, refetch: refetchFullStartupDetails } = FetchStartupDetails(slug)
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails(isTokenAvailable)

    localStorage.setItem("jwt", Cookies.get("jwt"))

    useEffect(() => {
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto' // cleanup or run on page unmount
        }
    }, [])

    const {
        mutateAsync: FetchStartupDocumentsAsync,
        status: fetchStartupDocumentsStatus,
        error: fetchStartupDocumentsError,
        isLoading: isUpdatingFetchStartupDocuments,
        data: fetchStartupDocumentsResponse,
    } = FetchStartupDocuments();

    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = SavePersonalInfo();

    const {
        mutateAsync: SaveToListAsync,
        status: saveToListStatus,
        error: saveToListError,
        isLoading: isUpdatingSaveToList,
        data: saveToListResponse,
    } = SaveToList();

    const {
        mutateAsync: SaveInterestAsync,
        status: saveInterestStatus,
        error: saveInterestError,
        isLoading: isUpdatingSaveInterest,
        data: saveInterestResponse,
    } = SaveInterest();

    const {
        mutateAsync: SaveFeedbackAsync,
        status: saveFeedbackStatus,
        error: saveFeedbackError,
        isLoading: isUpdatingSaveFeedback,
        data: saveFeedbackResponse,
    } = SaveFeedback();

    const {
        mutateAsync: SaveUserTypeAsync,
        status: saveUserTypeStatus,
        error: saveUserTypeError,
        isLoading: isUpdatingSaveUserType,
        data: saveUserTypeResponse,
    } = SaveUserType();

    const {
        mutateAsync: FetchStatusAsync,
        status: fetchStatusStatus,
        error: fetchStatusError,
        isLoading: isUpdatingFetchStatus,
        data: fetchStatusResponse,
    } = FetchStatus();

    const handleNamePopupOpen = () => {
        setOpenNamePopup(true);
    }

    const handleNamePopupClose = () => {
        setOpenNamePopup(false);
    }

    const handleNameInvestor = (values) => {
        // handleNamePopupClose()
        let first_name = values?.first_name
        let last_name = values?.last_name
        savePersonalInfo(first_name, last_name)
    }

    const savePersonalInfo = (first_name, last_name) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SavePersonalInfoAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleInterestPopupOpen = () => {
        // setOpenInterestPopup(true);
        if (isTokenAvailable) {
            if (enableInterest) {
                setButtonClickType("Interest")
                if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
                    setOpenNamePopup(true);
                } else {
                    setOpenInterestPopup(true);
                }
            } else {
                setAlertMessage(`You have already shown investment interest in ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Investment Interest")
                setShowAlert(true)
            }
        } else {
            setButtonClickType("Interest")
            setOpenLoginPopup(true)
        }
    }

    const handleInterestPopupClose = () => {
        setOpenInterestPopup(false);
        setButtonClickType(null)
    }

    const handleFeedbackPopupOpen = () => {
        if (isTokenAvailable) {
            if (enableFeedback) {
                setButtonClickType("Feedback")
                if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
                    setOpenNamePopup(true);
                } else {
                    setOpenFeedbackPopup(true);
                }
            } else {
                setAlertMessage(`You have already submitted feedback for ${startupDetail?.startup_name}. Please wait to hear back from the startup.`)
                setAlertHeading("Feedback")
                setShowAlert(true)
            }
        } else {
            setButtonClickType("Feedback")
            setOpenLoginPopup(true)
        }
    }
    const handleFeedbackPopupClose = () => {
        setOpenFeedbackPopup(false);
        setButtonClickType(null)
    }

    const handleInterestSave = (values) => {
        let intrested_amount = values?.intrested_amount
        saveInterest(intrested_amount)
    }

    const handleFeedbackSave = (values) => {
        saveFeedback(values)
    }

    const handleTypePopupOpen = () => {
        setOpenTypePopup(true);
    }

    const handleTypePopupClose = () => {
        setOpenTypePopup(false);
    }

    const handleUserType = (values) => {
        setType(values.type)
        let first_name = values?.first_name
        let last_name = values?.last_name
        let type = values.type
        saveUserType(first_name, last_name, type)
    }

    useEffect(() => {
        if (fullStartupDetailsData?.status != 403) {
            if (fullStartupDetailsData != null) {
                setStartupDetail(fullStartupDetailsData?.data)
                fetchDocuments(fullStartupDetailsData?.data)
                setShowNotFound(false)
                // setDocuments(documentsArray)
            } else {
                setStartupDetail(null)
                setDocuments([])
                setShowNotFound(true)
            }
        } else {
            setShowNotFound(true)
        }
    }, [fullStartupDetailsData])

    const fetchDocuments = (startupDetail) => {
        FetchStartupDocumentsAsync(startupDetail?._id).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    useEffect(() => {
        setUserDetail(profileDetailsData?.data)
        if (profileDetailsData?.data?.first_name?.trim()?.length == 0 || profileDetailsData?.data?.last_name?.trim()?.length == 0) {
            setTimeout(() => {
                setOpenNamePopup(true);
            }, 1000)
        } else {
            setOpenNamePopup(false)
        }
    }, [profileDetailsData])

    useEffect(() => {
        if (userDetail != null && buttonClickType != null) {
            switch (buttonClickType) {
                case 'Wishlist': {
                    isTokenAvailable && saveToList(userDetail?.first_name, userDetail?.last_name)
                    break
                }
                case 'Interest': {
                    // saveToList(first_name, last_name)
                    handleInterestPopupOpen()
                    break
                }
                case 'Feedback': {
                    // saveToList(first_name, last_name)
                    handleFeedbackPopupOpen()
                    break
                }
            }
        }
    }, [userDetail, isTokenAvailable])

    useEffect(() => {
        if (startupDetail != null && userDetail != null) {
            if (isTokenAvailable) {
                fetchStatusOfButtons()
            }
        }
    }, [startupDetail, userDetail])

    const fetchStatusOfButtons = () => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id
        }
        FetchStatusAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const handleLogOut = () => {
        dispatch({ type: SET_MENU, opened: false });
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('email', { path: '/' });
        setIsTokenAvailable(false)
        // navigate('/startup/login');
    };

    const addToMyList = () => {
        if (userDetail?.first_name?.trim()?.length > 0 && userDetail?.last_name?.trim()?.length > 0) {
            saveToList(userDetail?.first_name, userDetail?.last_name)
        } else {
            // ask for name
            handleNamePopupOpen()
        }
    }

    const saveToList = (first_name, last_name) => {
        let request = {
            startup_id: startupDetail?._id,
            first_name: first_name?.trim(),
            last_name: last_name?.trim()
        }
        SaveToListAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveInterest = (intrested_amount) => {
        let request = {
            startup_id: startupDetail?._id,
            intrested_amount: intrested_amount
        }
        SaveInterestAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveFeedback = (values) => {
        let request = {
            startup_id: startupDetail?._id,
            investor_id: userDetail?.investor_id,
            ratings: {
                problem: values?.problem,
                market: values?.market,
                product: values?.product,
                team: values?.team,
                traction: values?.traction
            },
            investible: values?.invest,
            feedback: values?.feedback
        }
        SaveFeedbackAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    const saveUserType = (first_name, last_name, type) => {
        let request = {
            first_name: first_name?.trim(),
            last_name: last_name?.trim(),
            type: type
        }
        SaveUserTypeAsync(request).catch(err => {

            toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        });
    }

    useEffect(() => {
        if (fetchStartupDocumentsResponse && fetchStartupDocumentsResponse?.data?.statusCode == 200) {
            setDocuments(fetchStartupDocumentsResponse.data?.data?.documents)
            // setDocuments(documentsArray)
        } else {
            fetchStartupDocumentsResponse?.data?.message && toast.error(<CustomToast message={fetchStartupDocumentsResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
    }, [fetchStartupDocumentsResponse?.data?.statusCode]);

    useEffect(() => {
        if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
            handleNamePopupClose()
            refetchProfileDetails()
        } else {
            savePersonalInfoResponse?.data?.message && toast.error(<CustomToast message={savePersonalInfoResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
    }, [savePersonalInfoResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveToListResponse && saveToListResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveToListResponse?.data?.message && toast.error(<CustomToast message={saveToListResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleNamePopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveToListResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveInterestResponse && saveInterestResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveInterestResponse?.data?.message && toast.error(<CustomToast message={saveInterestResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleInterestPopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveInterestResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveFeedbackResponse && saveFeedbackResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveFeedbackResponse?.data?.message && toast.error(<CustomToast message={saveFeedbackResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleFeedbackPopupClose()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveFeedbackResponse?.data?.statusCode]);

    useEffect(() => {
        if (saveUserTypeResponse && saveUserTypeResponse?.data?.statusCode == 200) {
            toast.success(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        } else {
            saveUserTypeResponse?.data?.message && toast.error(<CustomToast message={saveUserTypeResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                duration: 4000,
                position: "top-right",
                style: {
                    padding: "15px 30px",
                },
            });
        }
        handleTypePopupClose()
        redirectToDashdoard()
        setButtonClickType(null)
        // refetchFullStartupDetails()
        if (isTokenAvailable) {
            fetchStatusOfButtons()
        }
    }, [saveUserTypeResponse?.data?.statusCode]);

    useEffect(() => {
        if (fetchStatusResponse && fetchStatusResponse?.data?.statusCode == 200) {
            setEnableInterest(fetchStatusResponse?.data?.investorInfo == null || !notEmpty(fetchStatusResponse?.data?.investorInfo?.interested_amount))
            setEnableFeedback(fetchStatusResponse?.data?.feedback == null)
        } else {
            // fetchStatusResponse?.data?.message && toast.error(<CustomToast message={fetchStatusResponse?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //     duration: 4000,
            //     position: "top-right",
            //     style: {
            //         padding: "15px 30px",
            //     },
            // });
        }
    }, [fetchStatusResponse?.data?.statusCode]);

    const redirectToDashdoard = () => {
        if (type != null) {
            if (type == "Investor") {
                window.location.assign("https://backrr.in/dashboard")
            } else {
                window.location.assign("https://backrr.in/dashboard")
            }
        }
    }

    const openDocument = (document) => {
        const extension = document.filename?.split('.')?.pop()?.toLowerCase();
        if (extension == "pdf") {
            let title = document.title.replace(new RegExp(" ", "g"), "-")
            if (title == "Pitch-Deck") {
                title = title + "-Round"
            }
            navigate(`/startup/${slug}/d/${title.toLowerCase()}`, { state: document })
        } else {
            window.open(document.file_url)
        }
    }

    const ButtonsRow = () => {
        return <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
            {/* <B300 onClick={addToMyList} startIcon={<Add fontSize='1.25rem' />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px' }}>
                Add to my list
            </B300> */}
            <B300 onClick={handleInterestPopupOpen} startIcon={<ThumbUp sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Show Interest
            </B300>
            <B300 onClick={handleFeedbackPopupOpen} startIcon={<RateReview sx={{ height: 20 }} />} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 500, borderRadius: "8px", height: '36px', fontSize: '0.7rem' }} style={{ lineHeight: '13px' }}>
                Submit Feedback
            </B300>
        </Grid>
    }

    const renderListMode = (item, index) => {
        return (
            <Grid xs={12} style={{ padding: "0.5rem 0 0.5rem 0", borderBottom: index != documents?.length - 1 ? `1px solid ${theme.palette.customColors.g75}` : 0 }}>
                <Grid
                    onClick={() => { openDocument(item) }}
                    style={{ cursor: 'pointer' }}
                    sx={{ boxShadow: 'none', overflow: 'hidden', display: 'flex', flexDirection: "row", gap: { xs: "1rem", sm: "1.5rem" }, alignItems: 'center', padding: "0.5rem 0" }}
                >
                    <Box height={"2.8rem"}>
                        <img src={getFileIcon(item?.filename)} style={{ padding: '0', height: '100%', objectFit: 'cover', display: 'block' }} />
                    </Box>
                    <Box sx={{ flex: 1 }} alignItems={'center'} height={"2.8rem"} overflow={'hidden'}>
                        {item?.title && <Typography textAlign={"left"} sx={{ fontWeight: 600, fontSize: { xs: "0.7rem", sm: "0.9rem" }, marginLeft: { xs: "auto", sm: 0 }, wordBreak: 'break-word', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item?.title}</Typography>}
                        {item?.updatedAt && <Typography textAlign={"left"} sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" }, marginLeft: { xs: "auto", sm: 0 } }}>{moment(item.updatedAt).fromNow()}  {item.size?.length > 0 && '\u25CF'}  {item.size}</Typography>}
                    </Box>
                    <Box>
                        <RemoveRedEyeOutlinedIcon sx={{ fontSize: 20 }} />
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const renderGridMode = (item, index) => {
        return (
            <Grid item xs={6} style={{ padding: "0.5rem" }}>
                <Grid
                    onClick={() => { openDocument(item) }}
                    style={{ boxShadow: 'none', overflow: 'hidden', padding: '0.5rem', cursor: 'pointer', backgroundColor: theme.palette.customColors.g50, borderRadius: '15px' }}
                >
                    <Box display={'flex'} sx={{ placeContent: 'center', marginBottom: '0.5rem' }}>
                        <img src={getFileIcon(item?.filename)} style={{ width: '25%', aspectRatio: 1, objectFit: 'contain' }} />
                    </Box>
                    {item?.title && <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: { xs: "0.7rem", sm: "0.9rem" }, marginLeft: { xs: "auto", sm: 0 }, wordBreak: 'break-word', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item?.title}</Typography>}

                </Grid>
            </Grid>
        )
    }

    return (
        <AuthWrapper1 sx={{ height: '100vh' }}>
            <MainWrappper profileData={userDetail} startupData={fullStartupDetailsData} theme={theme}>
                <Helmet>
                    <title>{fullStartupDetailsData?.data != null ? fullStartupDetailsData?.data?.startup_name + "-Documents | Backrr" : "Backrr"}</title>
                    <meta name="description" content={fullStartupDetailsData?.data?.problem_solving} />
                    <meta property="og:title" content={fullStartupDetailsData?.data != null ? fullStartupDetailsData?.data?.startup_name + "-Documents | Backrr" : "Backrr"} />
                    <meta property="og:description" content={fullStartupDetailsData?.data?.problem_solving} />
                </Helmet>

                <main style={{ height: '87vh' }}>
                    {isTokenAvailable && <Grid position={'fixed'} right={10} top={10} zIndex={100}>
                        <IconButton onClick={handleLogOut}><Logout /></IconButton>
                    </Grid>}
                    {!isFullStartupDetailsLoading && fullStartupDetailsData?.data == undefined && !isUpdatingFetchStartupDocuments ? <Error404 /> : <Grid xs={12} container display={"block"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                        maxWidth: '900px !important',
                        marginInline: 'auto',
                        paddingTop: '0.5rem !important',
                    }}>
                        <Grid container xs={12} alignItems={"center"} spacing={2} pb={0.5} marginInline={"auto"} position={"sticky"} top={0} bgcolor={"#FFFAF5"} sx={{ zIndex: 1 }}>
                            <Grid item xs={12} pl={"0 !important"}>
                                {isFullStartupDetailsLoading ? <div className='skeleton' style={{ width: "6rem", height: "6rem", borderRadius: '3rem', marginInline: 'auto', display: "block" }} />
                                    :
                                    <>
                                        {
                                            showInitialLogo ? <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0, marginInline: 'auto', display: "block", }}><div style={{ width: "3.5rem", height: "3.5rem", borderRadius: '1.4rem', justifyContent: 'center', alignItems: 'center', backgroundColor: "#007bff", alignContent: 'center' }}>
                                                <Typography variant='h1' sx={{ fontSize: { xs: "0.5rem", md: "0.75rem" }, textAlign: 'center', marginInline: 'auto' }} color={'white'}>{startupDetail?.startup_name?.split(" ")[0]?.substring(0, 1)}{startupDetail?.startup_name?.split(" ")[1]?.substring(0, 1)}</Typography>
                                            </div></button>
                                                : <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0, marginInline: 'auto', display: "block" }}><img src={startupDetail != null ? startupDetail?.startup_logo_path : null} onLoad={() => { setShowInitialLogo(false) }} onError={() => { setShowInitialLogo(true) }} style={{ width: "5rem", height: "5rem", borderRadius: '5px', }} /></button>
                                        }
                                    </>}
                            </Grid>

                            <Grid xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
                                {isFullStartupDetailsLoading ? <div className='skeleton' style={{ marginInline: 'auto', width: '6rem', height: '1rem', marginBlock: 4 }} /> :
                                    <>
                                        {startupDetail?.startup_name && <button type='button' onClick={() => { navigate(`/startup/${slug}`) }} style={{ backgroundColor: 'transparent', padding: 0 }}><Typography variant='title' sx={{ fontSize: { xs: "1rem", md: "1rem" }, textAlign: 'center', marginInline: 'auto' }}>{startupDetail?.startup_name}</Typography></button>}
                                    </>
                                }
                            </Grid>
                            <Grid xs={12}>
                                <ButtonsRow />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} alignItems={"center"} spacing={2} marginInline={"auto"} display={"flex"} padding={{ xs: "1rem !important", md: "1rem 2rem !important" }}>
                            {(isFullStartupDetailsLoading || isUpdatingFetchStartupDocuments) ? <>
                                <Grid className='skeleton' style={{ width: "100%", height: "4rem" }} marginTop={2} />
                                <Grid className='skeleton' style={{ width: "100%", height: "10rem" }} marginTop={1} />
                            </>
                                :
                                <>
                                    <Grid item xs={12} marginInline={"auto"} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }} paddingRight={{ xs: 0, md: 2 }} >
                                        <IconButton onClick={() => { navigate(`/startup/${slug}`) }}>
                                            <ArrowBack style={{ height: 25, width: 25, color: theme.palette.customColors.indigo600 }} />
                                        </IconButton>
                                    </Grid>

                                    <Grid container xs={12} style={{ padding: '1rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '15px', backgroundColor: '#FFF' }} marginTop={2}>
                                        <Grid container xs={12} display={'flex'} justifyContent={'space-between'}>
                                            <Typography fontWeight={700} fontSize={"1rem"}>Documents</Typography>
                                            <IconButton onClick={() => { setListMode(prev => !prev) }} >
                                                {
                                                    listMode ? <GridViewOutlinedIcon sx={{ fontSize: 20 }} />
                                                        : <FormatListBulletedOutlinedIcon sx={{ fontSize: 20 }} />
                                                }
                                            </IconButton>
                                        </Grid>
                                        {documents?.length > 0 ? <Grid container rowSpacing={2} columnSpacing={2} overflow="auto" maxHeight={'45vh'} marginInline={'auto'} marginTop={1}>
                                            {
                                                documents?.map((item, index) => {
                                                    return listMode ? renderListMode(item, index) : renderGridMode(item, index)
                                                })
                                            }
                                        </Grid> : <Grid xs={12} alignItems={'center'} justifyContent={'center'}> <NoData /> </Grid>}
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>}
                    <Dialog fullScreen={true} open={openLoginPopup} onClose={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) }} sx={{}}>
                        <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.customColors.gray300}` }}>
                            <Grid sx={{ position: 'sticky', padding: "0", backgroundColor: '#FFF', zIndex: 1, marginBottom: '0.5rem' }} display={'flex'} alignItems={'center'}>
                                <Grid style={{ flex: 1 }}>
                                    <Typography fontWeight={700} fontSize={"1.3rem"} textAlign={'center'}>Login</Typography>
                                </Grid>
                                <IconButton disableRipple={true} sx={{ padding: 0 }}>
                                    <CloseIcon width={40} height={40} style={{ marginRight: "-15px" }} onClick={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined); setButtonClickType(null) }} />
                                </IconButton>
                            </Grid>
                        </DialogTitle>
                        <DialogContent sx={{ padding: 0 }}>
                            <LoginPopup handleClose={() => { setOpenLoginPopup(false); setIsTokenAvailable(Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined); refetchProfileDetails() }} />
                        </DialogContent>
                    </Dialog>
                    <ModalComponent open={openNamePopup} onClose={() => { }} style={{ maxWidth: 640, width: "100%" }}>
                        <NameInvestorPopup handleClose={handleNamePopupClose} handleSubmit={handleNameInvestor} />
                    </ModalComponent>
                    <ModalComponent open={openInterestPopup} onClose={handleInterestPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <SaveInterestPopup startupDetail = {startupDetail} data={userDetail} handleClose={handleInterestPopupClose} handleSubmit={handleInterestSave} />
                    </ModalComponent>
                    <ModalComponent open={openFeedbackPopup} onClose={handleFeedbackPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <FeedbackPopup data={userDetail} handleClose={handleFeedbackPopupClose} handleSubmit={handleFeedbackSave} />
                    </ModalComponent>
                    <ModalComponent open={openTypePopup} onClose={handleTypePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                        <UserTypePopup data={userDetail} handleClose={handleTypePopupClose} handleSubmit={handleUserType} />
                    </ModalComponent>
                    <AlertDialog open={showAlert} handleClose={() => { setShowAlert(false); setAlertMessage(null); setAlertHeading(null) }} heading={alertHeading} subheading={alertMessage} />
                </main>
            </MainWrappper>
        </AuthWrapper1>
    )
}

export default DataRoom