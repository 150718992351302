import { useRoutes } from 'react-router-dom';

// routes
import Cookies from 'js-cookie';
import AuthenticationRoutesInvestors from './AuthenticationRoutesInvestors';
import CreatProfileRoutesInvestors from './CreatProfileRoutesInvestors';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutesForInvestor() {
    var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) ? CreatProfileRoutesInvestors : AuthenticationRoutesInvestors;
    return useRoutes([routes])
}
