import { Toaster } from 'react-hot-toast';
import ThemeRoutes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/theme";
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useSearchParams, Route, Routes } from 'react-router-dom';
import ThemeRoutesForInvestor from './routes/ThemeRoutesForInvestor';
import ThemeRoutesForRedirect from './routes/ThemeRoutesForRedirect';
import Home from './views/pages/Home';

function App() {

  const [searchParams] = useSearchParams()

  useEffect(() => {
    let pathname = window.location?.pathname
    let splittedPathname = pathname.split("/")
    let slug = splittedPathname[2]
    let document = splittedPathname[3]
    let pitchDeck = splittedPathname[4]
    if (slug?.trim()?.length > 0) {
      !slug?.includes("verification") && !slug?.includes("login") && Cookies.set("slug", slug);
    } else {
      Cookies.remove("slug")
    }
    if (document?.trim()?.length > 0) {
      Cookies.set("document", document);
    } else {
      Cookies.remove("document")
    }
    if (pitchDeck?.trim()?.length > 0) {
      Cookies.set("pitchDeck", pitchDeck);
    } else {
      Cookies.remove("pitchDeck")
    }
    if (searchParams.get("q")?.trim()?.length > 0) {
      Cookies.set("mode", searchParams.get("q"));
    } else {
      Cookies.remove("mode")
    }
  }, [Cookies.get("slug")])

  return (
    <>
      <Toaster position='top-right' />
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/startup/*' Component={ThemeRoutes} />
          <Route path='/investor/*' Component={ThemeRoutesForInvestor} />
          <Route path='/callback/*' Component={ThemeRoutesForRedirect} />
          <Route path='/' Component={Home} />
          {/* <ThemeRoutes /> */}
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
