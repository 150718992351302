import LoginScreen from '../views/pages/LoginScreen';
import OTPScreen from '../views/pages/OTPScreen';
import StartupDetail from '../views/startupDetail';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '',
    children: [
        {
            path: '/',
            element: <LoginScreen />,
        },
        {
            path: '/login',
            element: <LoginScreen />,
        },
        {
            path: '/verification',
            element: <OTPScreen />,
        },
        {
            path: '*',
            element: <LoginScreen />,
        },
        {
            path: '/:slug',
            element: <StartupDetail />
        },
    ]
};

export default AuthenticationRoutes;
