import { useRoutes } from 'react-router-dom';

// routes
import Cookies from 'js-cookie';
import ThemeRoutes from '.';
import LinkedInRedirect from '../views/pages/LinkedInRedirect';
import LoginPopup from '../views/startupDetail/LoginPopup';
import { Grid } from '@mui/material';

const AuthenticationRoutes = {
    path: '',
    children: [
        {
            path: '',
            element: <Grid height={"100vh"}>
                <LoginPopup />
            </Grid>,
        },
    ]
};

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutesForRedirect() {
    var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) ? ThemeRoutes : AuthenticationRoutes;
    return useRoutes([routes])
}
