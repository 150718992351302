// material-ui
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// ==============================|| AUTHENTICATION 1 WRAPPER ||============================== //

const AuthWrapper1 = styled(Grid)(({ theme }) => ({
    backgroundColor: "#FFFAF5",
    height: '100vh',
}));

export default AuthWrapper1;
