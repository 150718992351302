import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
import { Box, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function ModalComponent({ open, onClose, closeIcon=false, children, mxclass, style={}, sx={} }) {
    const theme = useTheme()
    return (
        <Modal open={open} onClose={onClose} className={mxclass} sx={{...sx}}>
            <Box className='cs-modal' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '900px', ...style }}>
                {closeIcon && <Box sx={{ position: 'absolute', top: '0px', right: '0px', padding: '15px', cursor: 'pointer',zIndex: 1 }} onClick={onClose} className='cs-close'>
                    <AiFillCloseCircle size={25}  />
                </Box>}
               {children}
            </Box>
        </Modal>
    )
}

export default ModalComponent