import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";
import moment from "moment";

export const FetchStartupDetails = (slug) => {
    return useQuery(
        ['master-FetchStartupDetails-FetchStartupDetails-FetchStartupDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/startup-details-by-slug/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data[0], status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        enabled: !!slug,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchStartupPitchDeck = (slug) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchStartupPitchDeck-FetchStartupPitchDeck-FetchStartupPitchDeck-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-pitch-deck/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            // console.log(data,"new Data")
            return { data: savedData?.data, status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchProfileDetails = (isTokenAvailable) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchProfileDetails-FetchProfileDetails-FetchProfileDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/user-info`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            const personal_information = savedData?.personal_information
            const profile_picture = savedData?.profile_picture
            // console.log("savedData", savedData)
            let date = ""
            let month = ""
            let year = ""
            if (personal_information?.dob) {
                let dateOfBirth = moment(personal_information?.dob).format("MM-DD-YYYY")
                month = dateOfBirth.split("-")[0]
                date = dateOfBirth.split("-")[1]
                year = dateOfBirth.split("-")[2]
            }
            const savedValues = {
                // step 1
                first_name: personal_information?.first_name?.trim()?.length > 0 ? String(personal_information?.first_name) : '',
                last_name: personal_information?.last_name?.trim()?.length > 0 ? String(personal_information?.last_name) : '',
                title: personal_information?.title ? String(personal_information?.title) : '',
                dd: date,
                mm: month,
                yyyy: year,
                custom_image_url: profile_picture?.custom_image_url?.trim()?.length > 0 ? profile_picture?.custom_image_url : null,
                avatar_selected: profile_picture?.avatar_selected?.trim()?.length > 0 ? profile_picture?.avatar_selected : null,
                // mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                // phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                // country_id: savedData?.country_id ? savedData?.country_id : null,
                // state_id: savedData?.state_id ? savedData?.state_id : null,
                // city_id: savedData?.city_id ? savedData?.city_id : null,
                // stage_id: savedData?.stage_id ? savedData?.stage_id : "",
                // sector_id: savedData?.sector_id ? savedData?.sector_id : [],
                step: savedData?.step || 1,
                _id: savedData?._id,
                investor_id: savedData?.investor_id
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return isTokenAvailable && verifyToken(err)
        },
    })
}

export const SavePersonalInfo = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveToList = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/add_list`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveInterest = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveUserType = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/show_interested`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStatus = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-investor-info`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const SaveFeedback = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/submit-feedback`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchStartupDocuments = (startup_id) => {
    return useMutation(
        (startup_id) => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/startup-rounds/get_all_round_documents/${startup_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
}

export const SaveView = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/investors/add-investor-view`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                },
                data: payload,
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const FetchInvestorDetails = (slug) => {
    return useQuery(
        ['master-FetchInvestorDetails-FetchInvestorDetails-FetchInvestorDetails-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/profile/${slug}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data;
            return { data: savedData?.data, status: savedData?.statusCode }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchProfileDetailsofStartup = (isTokenAvailable) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-FetchProfileDetailsofStartup-FetchProfileDetailsofStartup-FetchProfileDetailsofStartup-fetch'],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL_FOR_INVESTOR}/startups/startup-profile-details`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data.data.data[0];
            const savedValues = {
                first_name: savedData?.first_name ? String(savedData?.first_name) : '',
                last_name: savedData?.last_name ? String(savedData?.last_name) : '',
                mobile_number: savedData?.mobile_number ? String(savedData?.mobile_number) : '',
                phone_code: savedData?.phone_code ? String(savedData?.phone_code) : '91',
                country: savedData?.country ? savedData?.country : null,
                state: savedData?.state ? savedData?.state : null,
                city: savedData?.city ? savedData?.city : null,
                linkedin_url: savedData?.linkedin_url ? String(savedData?.linkedin_url)?.startsWith('https://') ? String(savedData?.linkedin_url)?.slice(8) : String(savedData?.linkedin_url) : '',
                startup_logo: (savedData?.startup_logo_path),
                startup_name: savedData?.startup_name ? savedData?.startup_name : "",
                entity_name: savedData?.entity_name ? savedData?.entity_name : "",
                formation_date: savedData?.formation_date ? savedData?.formation_date : null,
                formatted_formation_date: savedData?.formatted_formation_date ? savedData?.formatted_formation_date : null,
                sector_info: savedData?.sector_info ? savedData?.sector_info : "",
                raised_round: savedData?.raised_round ? savedData?.raised_round : null,
                stage_info: savedData?.stage_info ? savedData?.stage_info : "",
                category_info: savedData?.category_info ? savedData?.category_info : "",
                business_model_info: savedData?.business_model_info ? savedData?.business_model_info : "",
                business_model_id: savedData?.business_model_id ? savedData?.business_model_id : [],
                pitch_deck_path: savedData?.pitch_deck_path ? savedData?.pitch_deck_path : null,
                pitch_deck_thumbnail_path: savedData?.pitch_deck_thumbnail_path ? savedData?.pitch_deck_thumbnail_path : null,
                pitch_deck_url: savedData?.pitch_deck_url ? savedData?.pitch_deck_url : null,
                round_name: savedData?.round_name || "",
                round_type: savedData?.round_type || "",
                description: savedData?.description || '',
                elevator_pitch_path: savedData?.elevator_pitch_path ? savedData?.elevator_pitch_path : null,
                problem_solving: savedData?.problem_solving || "",
                website_url: savedData?.website_url ? String(savedData?.website_url)?.startsWith('https://') ? String(savedData?.website_url)?.slice(8) : String(savedData?.website_url) : '',

                coFoundersArray:
                    savedData?.cofounder && savedData?.cofounder?.length > 0 ? savedData?.cofounder?.map(item => (
                        {
                            edit: true,
                            name: item?.name || '',
                            title: item?.title || '',
                            bio: item?.bio || '',
                            linkedin_url: item?.linkedin_url ? String(item?.linkedin_url) : '',
                            image: item?.cofounder_image_path ? item?.cofounder_image_path : null,
                            image_name: item?.cofounder_image ? item?.cofounder_image : null,
                        }
                    )) : [],
                referral_type: savedData?.referral_type || '',
                referral_name: savedData?.referral_name || '',
                founder: savedData?.founder && savedData?.founder?.length > 0 ? {
                    name: savedData?.founder[0]?.name || '',
                    bio: savedData?.founder[0]?.bio || '',
                    title: savedData?.founder[0]?.title || '',
                    linkedin_url: savedData?.founder[0]?.linkedin_url ? savedData?.founder[0]?.linkedin_url : '',
                    image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null,
                    founder_image: savedData?.founder[0]?.founder_image_path ? savedData?.founder[0]?.founder_image_path : null
                } : null,
                step: savedData?.step || 1,
                _id: savedData?._id,
                document_base_url: savedData?.document_base_url,
                backrr_base_url: savedData?.backrr_base_url,
                round_name: savedData?.round_info?.round_name,
                round_type: savedData?.round_info?.round_type,
                round_id: savedData?.round_info?._id,
                raised_round: savedData?.round_info?.raised_round,
            }
            return { data: savedValues, status: savedData?.success }
        },
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return isTokenAvailable && verifyToken(err)
        },
    })
}