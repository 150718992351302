import React from 'react'
import { Helmet } from 'react-helmet'
import logo from "../../assets/backrr_logo_symbol_white_home.png"

export default function Home() {
    return (
        <div className='home'>

            {/* <Helmet>
                <title>Super tool for startups and investors | Backrr</title>
                <meta name="description" content={"The all-in-one platform for investors and startups to connect, track portfolios, streamline deals, and drive growth effortlessly."} />
                <meta name="keywords" content="Startups, Angel Investors, Venture Capitalists, Funding Platform, Startup Collaboration, Investment Management, Startup Profiles, VC Funding, Investor-Startup Platform, Fundraising, Startup Showcase, Angel Funding, Backrr, Fund Management, Investment Collaboration" />
                <meta property="og:title" content={"Super tool for startups and investors | Backrr"} />
                <meta property="og:description" content={"The all-in-one platform for investors and startups to connect, track portfolios, streamline deals, and drive growth effortlessly."} />
                <meta property="og:url" content="https://www.backrr.com" />
                <meta property="og:image" content="https://www.backrr.com/backrr_favicon.png" />
            </Helmet> */}

            <header>
                <div className="home-logo">
                    <img src={logo} alt="Backrr Logo" />
                </div>
            </header>


            <main className='home-main'>
                <section className="content">
                    <h1><b style={{ fontFamily: 'DM Sans, sans-serif' }}>Operating System for Every Startup </b></h1>
                    <h1><b style={{ fontFamily: 'DM Sans, sans-serif' }}>and Investor to Collaborate Effortlessly</b></h1>
                    <h2><b>F O U N D E R S</b> &nbsp; <span> C R E A T E. </span> &nbsp; <b>I N V E S T O R S</b> &nbsp; <span> E L E V A T E. </span></h2>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSduXEBC1OcMhw_q8fKJFxEG4KbYU12ie5BC4gFIQ-vy8FBbbQ/viewform" target="_blank">
                        <button class="custom-btn">
                            Get Early Access
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </a>
                </section>
            </main>

            <footer className='home-footer'>
                <p>All rights reserved. Backrr Tech Labs Private Limited.</p>
            </footer>
        </div>
    )
}
