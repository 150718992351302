import { useTheme } from "@emotion/react";
import { CircularProgress, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import googleIcon from "../../assets/google_icon.png"
import CustomInputField from "../../ui-components/CustomInputField";
import { G300, WHITEBG } from "../../ui-components/CustomButton";
import { B300 as CustomButton } from '../../ui-components/CustomButton';
import { LoginViaEmail, VerifyOTP } from "../../api";
import BackrrLogoImage from "../../ui-components/BackrrLogoImage";
import Cookies from "js-cookie";
import AuthWrapper1 from "../pages/AuthWrapper1";
import { auth, provider, signInWithPopup, signInWithRedirect, signOut } from "../../firebase";
import { useNavigate } from "react-router-dom";
import LinkedInLogin from "../startupDetail/LinkedInLogin";

const inputProps = {
  maxLength: 1,
  inputMode: 'numeric',
  pattern: "[0-9]*",
  style: {
    textAlign: 'center',
  },
  onKeyDown: (event) => {
    if (event.key === '-' || event.keyCode === 189) {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  },
  onInput: (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = inputValue;
  }
};

const GoogleLogin = ({ loginMethod, setLoginMethod, isLoading, loginViaEmailAsync, handleCloseLogin }) => {
  const currentIP = window.location.host;
  const navigate = useNavigate();
  const theme = useTheme();
  const handleSignInGoogle = async () => {
    Cookies.remove('userDetails');
    setLoginMethod("google");
    await signOut(auth);
    await signInWithPopup(auth, provider)
      .then((result) => {
        // console.log(result, "result?.user");
        Cookies.set("email", result?.user?.email)
        Cookies.set("userDetails", JSON.stringify({ first_name: result?._tokenResponse?.firstName, last_name: result?._tokenResponse?.lastName, mobile_number: result?.user?.phoneNumber }))
        loginViaEmailAsync({
          "email": result?.user?.email,
          "devices": {
            "device_token": `${Math.random() * 10}`,
            "device_type": "web"
          },
          "last_login_ip": `${currentIP}`,
          "auth_provider": "google"
        }, {
          onSuccess: (response) => {
            Cookies.set("jwt", response?.data?.data?.userData?.token);
            handleCloseLogin();
          }
        });
      })
      .catch((error) => {
        console.error('Error signing in:', error);
      });
  };
  return <WHITEBG sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: '55px' }} onClick={() => handleSignInGoogle()} fullWidth>
    {isLoading && loginMethod === "google" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid display={'flex'} gap={1} flexDirection={"row"} alignItems={'center'}>
      <img src={googleIcon} width={24} height={24} />
      <Typography variant="caption" sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}>
        Login via Google
      </Typography>
    </Grid>}
  </WHITEBG>
}

const LoginPopup = ({ data, handleClose }) => {
  const theme = useTheme();
  const currentIP = window.location.host;
  const [loginMethod, setLoginMethod] = useState("email");
  const [emailValue, setEmailValue] = useState();
  const [emailError, setEmailError] = useState(false);
  const [showOtpView, setShowOtpView] = useState(false);

  const [error, setError] = useState(null)
  const [countDown, setCountDown] = useState(30);
  const [otpValues, setOtpValues] = useState(Array(4).fill(''));
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const { mutateAsync: loginViaEmailAsync, status: LoginViaEmailStatus, error: LoginViaEmailError, isLoading, data: loginViaEmailResponse } = LoginViaEmail();
  const { mutateAsync: verifyOTPAsync, isLoading: isOTPLoading, data: verifyOtpResponse } = VerifyOTP();

  useEffect(() => {
    if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode === 200) {
      // navigate("/startup/verification");
      setShowOtpView(true)
    }
  }, [loginViaEmailResponse?.data?.statusCode]);

  useEffect(() => {
    if (otpValues?.join("")?.trim()?.length > 0) {
      if (verifyOtpResponse?.data?.statusCode === 200) {
        setTimeout(() => {
          setError(null)
          Cookies.set("jwt", verifyOtpResponse?.data?.data?.token);
          handleClose()
          // if (slug?.length > 0) {
          //     if (pitchdeck?.length > 0) {
          //         if (pitchdeck?.trim() == "pitchdeck") {
          //             navigate(`/startup/${slug}/d/pitchdeck`)
          //         } else {
          //             navigate(`/startup/`)
          //         }
          //     } else {
          //         if (mode?.length > 0) {
          //             if (mode?.trim() == "funding-round") {
          //                 navigate(`/startup/${slug}?q=funding-round`)
          //             } else {
          //                 navigate(`/startup/${slug}`)
          //             }
          //         } else {
          //             if (document?.length > 0) {
          //                 navigate(`/startup/${slug}/d`)
          //             } else {
          //                 navigate(`/startup/${slug}`)
          //             }
          //         }
          //     }
          // } else {
          //     // navigate("/startup/")
          // }
        }, 100);
      } else if (verifyOtpResponse?.data?.statusCode !== 200) {
        // console.log(verifyOtpResponse?.data?.message, "co")
        verifyOtpResponse?.data?.message && setError(verifyOtpResponse?.data?.message || null)

      }
    }
  }, [verifyOtpResponse?.data?.statusCode]);

  const handleLogin = () => {
    setLoginMethod("email");
    loginViaEmailAsync({
      "email": emailValue,
      "devices": {
        "device_token": `${Math.random() * 10}`,
        "device_type": "web"
      },
      "last_login_ip": `${currentIP}`,
      "auth_provider": "email"
    }, {
      onSuccess: () => {
        Cookies.set("email", emailValue);
      },
      onError: () => {
        setEmailError(true);
      }
    });
  };

  const handleSubmit = () => {
    let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailPattern?.test(emailValue)) {
      handleLogin();
    }
    else {
      setEmailError(true);
    }
  };

  const handleResendingOTP = () => {
    loginViaEmailAsync({
      email: Cookies.get("email"),
      devices: { device_token: `${Math.random() * 10}`, device_type: "web" },
      last_login_ip: `${currentIP}`,
      auth_provider: "email"
    }).then(() => {
      setCountDown(30);
    });
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);
    setError(null);
  };

  const handleKeyDown = (event, index) => {
    // Get the reference to the current input element
    // const currentRef = inputRefs[index];

    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
    if (event.key === 'Enter') {
      handleVerifyOTP();
    }

    // Check if the arrow keys are pressed
    if (event.key === 'ArrowRight') {
      // Move focus to the next input if it's not the last one
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1]?.current?.focus();
      }
    } else if (event.key === 'ArrowLeft') {
      // Move focus to the previous input if it's not the first one
      if (index > 0) {
        inputRefs[index - 1]?.current?.focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    // Get the pasted data and filter out non-numeric characters
    const pastedData = event.clipboardData
      .getData('text')
      .replace(/\D/g, '') // Remove all non-numeric characters
      .slice(0, 4); // Ensure only the first 4 characters are processed

    const newOtpValues = [...otpValues];

    pastedData.split('').forEach((char, index) => {
      if (inputRefs[index]) {
        inputRefs[index].current.value = char;
        newOtpValues[index] = char;
      }
    });

    setOtpValues(newOtpValues);
  };


  const handleVerifyOTP = () => {
    const OTP = otpValues.join('');
    if (OTP?.trim()?.length > 0) { verifyOTPAsync({ email: Cookies.get("email"), otp: OTP }); }
    else {
      setError("Please enter OTP");
    }
  };

  useEffect(() => {
    if (showOtpView) {
      inputRefs[0].current.focus();
    }
  }, [showOtpView]);

  useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  return (
    <AuthWrapper1 sx={{ height: '100%' }} theme={theme}>
      <Paper
        sx={{
          background: theme.palette.customColors.whiteBG,
          height: "100%"
        }}
      >
        {
          showOtpView ?
            <Grid sx={{ display: "grid", placeContent: 'center', height: "100%" }}>
              <BackrrLogoImage alignCenter={true} />
              <Paper sx={{
                backgroundColor: '#FFFAF5', boxShadow: 0, padding: '2rem', flex: 1, overflowY: "auto", overflowX: "hidden", maxHeight: "578px", borderRadius: "15px",
                // scale: {xs: "0.8", sm: "0.9", lg: "1"},
                border: `1px solid ${theme.palette.customColors.g75}`,
                scale: "0.9",
                minWidth: 310,
                // width: '80%',
                maxWidth: { xs: '100%', sm: 350 },
                // marginInline: 'auto'
              }}>
                <Grid container xs={12} display={'grid'} sx={{ placeItems: "center", placeContent: "stretch", gap: "1rem" }}>
                  <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}>Verify with OTP</Typography>
                  <Typography color={"gray"} textAlign={{ xs: "center" }} variant="bodyTextRegular" sx={{ marginInline: { xs: 'auto', md: 0 } }}>
                    {Cookies.get("email") ? "We have sent you an OTP on " + Cookies.get("email") : 'No Email Available'}
                  </Typography>
                  <Typography color={"gray"} variant="subtitle2" mt={"2rem"} mb={"0.5rem"}>ENTER OTP</Typography>
                  <Grid container xs={12} gap={2}>
                    {inputRefs.map((ref, index) => (
                      <Grid item flex={1} key={index}>
                        <CustomInputField
                          inputProps={inputProps}
                          required
                          sx={{ marginBottom: 0 }}
                          inputRef={ref}
                          onChange={(event) => handleInputChange(event, index)}
                          onKeyDown={(event) => handleKeyDown(event, index)}
                          onPaste={index === 0 ? handlePaste : undefined}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {error !== null && <span style={{ marginTop: "-0.5rem", color: "rgb(239, 83, 80)", fontWeight: "400", fontSize: "0.75rem", lineHeight: "1.66", letterSpacing: "0.03333em", textAlign: "left", marginRight: "14px", marginBottom: "0", marginLeft: "14px", }}>{error}</span>}
                  {isLoading ? (
                    <Typography color={theme.palette.customColors.b300} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '1rem' }} fontSize="1rem">
                      <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" /> <span>Resending OTP</span>
                    </Typography>
                  ) : (
                    <Typography color={theme.palette.customColors.b300} fontSize="1rem" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', textUnderlineOffset: '5px', marginTop: '1rem' }}>
                      <span onClick={countDown === 0 ? handleResendingOTP : undefined} style={{ color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300, cursor: 'pointer' }}>Resend OTP</span>
                      <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                    </Typography>
                  )}
                </Grid>
                <Grid display={"grid"} gap={1} xs={12} sx={{ width: "100%", marginTop: '1rem' }}>
                  <CustomButton disabled={isOTPLoading} sx={{ background: theme.palette.customColors.B300, borderRadius: "8px", textTransform: "none", maxWidth: "100%", marginTop: '1rem', padding: "12px 20px", minHeight: '55px', maxHeight: '60px' }} fullWidth onClick={handleVerifyOTP}>
                    {isOTPLoading ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size="1.4rem" /> : "Verify OTP"}
                  </CustomButton>
                </Grid>
              </Paper>
            </Grid>
            :
            <Grid sx={{ display: "grid", placeContent: 'center', height: "100%" }}>
              <BackrrLogoImage alignCenter={true} />
              <Paper sx={{
                backgroundColor: '#FFFAF5', boxShadow: 0, padding: '2rem', flex: 1, overflowY: "auto", overflowX: "hidden", maxHeight: "578px", borderRadius: "15px",
                // scale: {xs: "0.8", sm: "0.9", lg: "1"},
                border: `1px solid ${theme.palette.customColors.g75}`,
                scale: "0.9",
                minWidth: { xs: 310, sm: 355 },
                // width: '80%',
                maxWidth: { xs: '100%', sm: 380 },
                // marginInline: 'auto'
              }}>
                <Grid container xs={12} display={'grid'} sx={{ placeItems: "center", placeContent: "stretch", gap: "1rem" }}>
                  <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}> Continue with email</Typography>
                  {/* <Typography variant='bodyTextRegular' color={theme.palette.customColors.g100} marginTop={0} sx={{ textAlign: { xs: 'center', md: "start" } }}>
                    Login or signup with options below
                  </Typography> */}
                  <CustomInputField sx={{ margin: 0 }} value={emailValue} onKeyDown={(e) => { if (e.key === "Enter") { handleSubmit(); } }} onChange={(event) => {
                    setEmailValue(String(event.target.value)?.toLowerCase()); setEmailError(false);
                  }} hasError={emailError} errorMessage={"Please enter a valid email"} labelStyle={{ marginTop: "2rem" }} fullWidth={true} label="EMAIL" type="email" placeholder='' />
                </Grid>
                <Grid display={"grid"} gap={1} xs={12} sx={{ width: "100%", marginTop: '1rem' }}>
                  <G300 fullWidth={true} sx={{ background: theme.palette.customColors.b300, ':hover': { background: theme.palette.customColors.b300 }, ":focus": { background: theme.palette.customColors.b300 }, borderRadius: "8px", textTransform: "none", height: '55px', fontSize: '1rem', marginTop: "1rem" }} onClick={() => handleSubmit()}>
                    {isLoading && loginMethod === "email" ?
                      <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} />
                      : "Next"}
                  </G300>
                  <Grid position={'relative'}
                    sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <Divider sx={{ flex: 1 }} />
                    <Typography sx={{ width: "2rem", textAlign: "center" }}>or</Typography>
                    <Divider sx={{ flex: 1 }} />
                  </Grid>
                  <GoogleLogin loginMethod={loginMethod} isLoading={isLoading} setLoginMethod={setLoginMethod} loginViaEmailAsync={loginViaEmailAsync} handleCloseLogin={handleClose} />
                  {<LinkedInLogin loginMethod={loginMethod} setLoginMethod={setLoginMethod} handleCloseLogin={handleClose} />}
                </Grid>
              </Paper>
            </Grid>
        }
      </Paper>
    </AuthWrapper1>
  );
};

export default LoginPopup;
