import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import Cookies from 'js-cookie';
import CreatProfileRoutes from './CreatProfileRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) ? CreatProfileRoutes : AuthenticationRoutes;
    return useRoutes([routes])
}
