import { Box, useMediaQuery } from '@mui/material'
import React from 'react'
import theme from '../themes/theme';

const ThemeBGWrapper = ({ children, bgColor = "white" }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    return (
        <Box sx={{ background: bgColor, minHeight: 'calc(100vh - 20px)', maxWidth: "100%" , height: '100%', borderRadius: "0px 0 0 0", position: 'fixed', top: 0 , width: '100%', right: 0}}>
            {children}
        </Box>
    )
}

export default ThemeBGWrapper