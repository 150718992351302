import { useTheme } from '@emotion/react'
import { CircularProgress, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { G300, } from '../../ui-components/CustomButton'
import { useNavigate } from 'react-router-dom';
import AuthWrapper1 from './AuthWrapper1';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage'
import CustomInputField from '../../ui-components/CustomInputField';
import { LoginViaEmail } from '../../api';
import Cookies from 'js-cookie'

const LoginScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const currentIP = window.location.host;
    const [userData, setUserData] = React.useState(null);
    const [loginMethod, setLoginMethod] = React.useState("email");
    const { mutateAsync: loginViaEmailAsync, status: LoginViaEmailStatus, error: LoginViaEmailError, isLoading, data: loginViaEmailResponse } = LoginViaEmail();

    React.useEffect(() => {
        if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode === 200) {
            navigate("/startup/verification");
        }
        if (loginViaEmailResponse && loginViaEmailResponse?.data?.statusCode !== 200) {
            //   toast.error(<CustomToast message={loginViaEmailResponse?.data.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />, {
            //     duration: 4000,
            //     position: 'top-right',
            //     style: {
            //       padding: "15px 30px"
            //     }
            //   });
        }
    }, [loginViaEmailResponse?.data?.statusCode]);

    const [emailValue, setEmailValue] = React.useState();
    const [emailError, setEmailError] = React.useState(false);

    const handleLogin = () => {
        setLoginMethod("email");
        loginViaEmailAsync({
            "email": emailValue,
            "devices": {
                "device_token": `${Math.random() * 10}`,
                "device_type": "web"
            },
            "last_login_ip": `${currentIP}`,
            "auth_provider": "email"
        }, {
            onSuccess: () => {
                Cookies.set("email", emailValue);
            },
            onError: () => {
                setEmailError(true);
            }
        });
    };

    const handleSubmit = () => {
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailPattern?.test(emailValue)) {
            handleLogin();
        }
        else {
            setEmailError(true);
        }
    };
    const isTableorLargerScreen = useMediaQuery(theme.breakpoints.up('sm'))
    //theme.palette.background.paper
    // "#FFFFFF"
    return (
        <AuthWrapper1 sx={{ background: '#fff' }}>
            <Grid sx={{ display: "grid", placeContent: 'center', height: "100vh" }}>
                <BackrrLogoImage alignCenter={true} />
                <Paper sx={{
                    backgroundColor: '#FFFAF5', boxShadow: 0, padding: '2rem', flex: 1, overflowY: "auto", overflowX: "hidden", maxHeight: "578px", borderRadius: "15px",
                    // scale: {xs: "0.8", sm: "0.9", lg: "1"},
                    border: `1px solid ${theme.palette.customColors.g75}`,
                    scale: "0.9",
                    minWidth: { xs: 310, sm: 355 },
                    // width: '80%',
                    maxWidth: { xs: '100%', sm: 380 },
                    // marginInline: 'auto'
                }}>
                    <Grid container xs={12} display={'grid'} sx={{ placeItems: "center", placeContent: "stretch", gap: "1rem" }}>
                        <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}> Continue with email</Typography>
                        {/* <Typography variant='bodyTextRegular' color={theme.palette.customColors.g100} marginTop={"0px"} sx={{ textAlign: { xs: 'center', md: "start" } }}>
                            Start your investor journey now!
                        </Typography> */}
                        {/* Content */}
                        <CustomInputField sx={{ margin: 0 }} value={emailValue} onKeyDown={(e) => { if (e.key === "Enter") { handleSubmit(); } }} onChange={(event) => {
                            setEmailValue(String(event.target.value)?.toLowerCase()); setEmailError(false);
                        }} hasError={emailError} errorMessage={"Please enter a valid email"} labelStyle={{ marginTop: "2rem" }} fullWidth={true} label="EMAIL" type="email" placeholder='' />
                    </Grid>
                    {/* Footer */}
                    <Grid display={"grid"} gap={1} xs={12} sx={{ width: "100%", marginTop: '1rem' }}>
                        <G300 fullWidth={true} sx={{ background: theme.palette.customColors.b300, ':hover': { background: theme.palette.customColors.b300 }, ":focus": { background: theme.palette.customColors.b300 }, borderRadius: "8px", textTransform: "none", height: '55px', fontSize: '1rem', marginTop: "1rem" }} onClick={() => handleSubmit()}>
                            {isLoading && loginMethod === "email" ?
                                <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} />
                                : "Next"}
                        </G300>
                    </Grid>
                </Paper>
            </Grid>
        </AuthWrapper1>
    )
}

export default LoginScreen