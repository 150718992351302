import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/CustomInputField";
import { B300 } from "../../ui-components/CustomButton";
import * as Yup from "yup";
import { getInterestOptions } from "../../utils";

const SaveInterestPopup = ({ startupDetail, data, handleClose, handleSubmit }) => {
  const theme = useTheme();
  const [selectedInterest, setSelectedInterest] = useState(null)

  const validations = Yup.object(
    {
      intrested_amount: Yup.number("Please enter valid amount").required("Please enter valid amount").moreThan(0, "Please enter valid amount")
    }
  )

  const formik = useFormik({
    initialValues: {
      intrested_amount: 0
    },
    // validateOnMount: true,
    validationSchema: validations,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "20px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Submit Investment Interest
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Provide your investment interest amount
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                name="intrested_amount"
                label={"Enter Investment Amount"}
                value={startupDetail?.currency_info?.code + formik.values.intrested_amount}
                onChange={(e) => {
                  formik.setFieldValue("intrested_amount", e.target.value?.replace(startupDetail?.currency_info?.code, ""));
                  setSelectedInterest(null)
                }}
                hasError={formik.errors.intrested_amount}
                errorMessage={formik.errors.intrested_amount}
              />
            </Grid>
            <Grid item sx={12} className="grid">
              {
                getInterestOptions(startupDetail?.currency_info?.code).map(item => {
                  return (
                    <button type="button" className={`item ${item.label === selectedInterest && "selected-item"}`} onClick={() => { setSelectedInterest(item.label); formik.setFieldValue("intrested_amount", item.value); }}>{item.label}</button>
                  )
                })
              }
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Submit
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default SaveInterestPopup;
