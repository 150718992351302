import { useTheme } from "@emotion/react";
import { Divider, FormControlLabel, Grid, IconButton, Paper, RadioGroup, Rating, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/CustomInputField";
import { B300 } from "../../ui-components/CustomButton";
import * as Yup from "yup";
import CustomLabel from "../../ui-components/CustomLabel";
import CustomRadio from "../../ui-components/CustomRadio";
import CustomValidationText from "../../ui-components/CustomValidationText";

const FedbackPopup = ({ handleClose, handleSubmit }) => {
  const theme = useTheme();
  const [rate, setRate] = React.useState();
  const validations = Yup.object(
    {
      problem: Yup.number().required("Rating Required"),
      market: Yup.number().required("Rating Required"),
      product: Yup.number().required("Rating Required"),
      team: Yup.number().required("Rating Required"),
      traction: Yup.number().required("Rating Required"),
      invest: Yup.boolean().required("Please select if this is investable"),
    }
  )

  const formik = useFormik({
    initialValues: {
      problem: "",
      market: "",
      product: "",
      team: "",
      traction: "",
      invest: "",
      feedback: "",
    },
    validateOnMount: false,
    validationSchema: validations,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "20px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12} pb={1}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Write a Review
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Provide rating based on the following parameters
              </Typography>
            </Grid>
            <Grid item container gap={1.5}>
              <Grid item xs={12}>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontWeight={500} fontSize={'0.8rem'}>PROBLEM</Typography>
                  <Rating
                    name="problem"
                    precision={0.5}
                    sx={{ gap: '8px' }}
                    value={formik.values?.problem}
                    onChange={(e) => {
                      // setRate(newValue);
                      formik.setFieldValue("problem", e.target.value)

                    }}
                  />
                </Grid>
                {formik?.errors?.problem && <CustomValidationText value={formik?.errors?.problem} style={{ marginLeft: 0 }} />}
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid item xs={12}>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontWeight={500} fontSize={'0.8rem'}>MARKET</Typography>
                  <Rating
                    name="market"
                    precision={0.5}
                    sx={{ gap: '8px' }}
                    value={formik.values?.market}
                    onChange={(e) => {
                      formik.setFieldValue("market", e.target.value)
                    }}
                  />
                </Grid>
                {formik?.errors?.market && <CustomValidationText value={formik?.errors?.market} style={{ marginLeft: 0 }} />}
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid item xs={12}>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontWeight={500} fontSize={'0.8rem'}>PRODUCT</Typography>
                  <Rating
                    name="product"
                    precision={0.5}
                    sx={{ gap: '8px' }}
                    value={formik.values?.product}
                    onChange={(e) => {
                      formik.setFieldValue("product", e.target.value)
                    }}
                  />
                </Grid>
                {formik?.errors?.product && <CustomValidationText value={formik?.errors?.product} style={{ marginLeft: 0 }} />}
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid item xs={12}>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontWeight={500} fontSize={'0.8rem'}>TEAM</Typography>
                  <Rating
                    name="team"
                    precision={0.5}
                    sx={{ gap: '8px' }}
                    value={formik.values?.team}
                    onChange={(e) => {
                      formik.setFieldValue("team", e.target.value)
                    }}
                  />
                </Grid>
                {formik?.errors?.team && <CustomValidationText value={formik?.errors?.team} style={{ marginLeft: 0 }} />}
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid item xs={12}>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Typography fontWeight={500} fontSize={'0.8rem'} >TRACTION</Typography>
                  <Rating
                    name="traction"
                    precision={0.5}
                    sx={{ gap: '8px' }}
                    value={formik.values?.traction}
                    onChange={(e) => {
                      formik.setFieldValue("traction", e.target.value)
                    }}
                  />
                </Grid>
                {formik?.errors?.traction && <CustomValidationText value={formik?.errors?.traction} style={{ marginLeft: 0 }} />}
              </Grid>
              <Divider sx={{ width: '100%' }} />
              <Grid item xs={12} pt={1}>
                <Typography fontWeight={500} fontSize={'0.8rem'}>IS THIS INVESTABLE?</Typography>
                <RadioGroup
                  sx={{ flexDirection: "row", paddingTop: 1 }}
                  name="invest"
                  value={formik.values?.invest}
                  onChange={(e) =>
                    formik.setFieldValue("invest", e.target.value)
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<CustomRadio disableRipple />}
                    label={"Yes"}
                  />
                  <FormControlLabel
                    value={false}
                    control={<CustomRadio disableRipple />}
                    label={"No"}
                  />
                </RadioGroup>
                {formik?.errors?.invest && <CustomValidationText value={formik?.errors?.invest} style={{ marginLeft: 0 }} />}
              </Grid>
              <Grid item xs={12} pt={0.8} >
                <Typography fontWeight={500} fontSize={'0.8rem'} pb={1}>FEEDBACK (OPTIONAL)</Typography>
                <CustomInputField
                  // label={"FEEDBACK (OPTIONAL)"}
                  name={`feedback`}
                  labelStyle={{ marginTop: '0rem', fontWeight: 500, fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.87) !important' }} onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 250) {
                      formik.setFieldValue("feedback", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                    }
                    else {
                      formik.setFieldValue("feedback", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 250))
                    }
                  }} multiline minRows={4} maxRows={4} maxLength={250} fullWidth data-problem-solving={250 - formik.values?.feedback?.length + `${(250 - formik.values?.feedback?.length) > 1 ? " characters" : " character"} left`}
                  sx={{
                    "::after": {
                      content: 'attr(data-problem-solving)',
                      position: "absolute",
                      bottom: formik.errors.feedback ? 30 : 5,
                      right: 5,
                      background: 'white',
                      color: 'graytext',
                      padding: "0 5px",
                      fontSize: "0.75rem"
                    },
                  }}
                  value={formik.values?.feedback}
                  placeholder=""
                />

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Submit
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default FedbackPopup;
